// 引入路由
import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from "vue-router";
// 引入页面
import index from "../view/index"
import lowPass from "../view/lowPass"
import lowMom from "../view/lowMom"
import lowNumber from "../view/lowNumber"
import industry from "../view/industry"
import about from "../view/about"

// 配置信息中需要的页面相关配置

//配置页面
const routes = [{
		path: "/", //访问的路径
		component: index //对应的组件
	},
	{
		path: "/lowPass", //访问的路径
		component: () => import('../view/lowPass.vue') //对应的组件
	},
	{
		path: "/lowMom", //访问的路径
		component: lowMom //对应的组件
	},
	{
		path: "/lowNumber", //访问的路径
		component: lowNumber //对应的组件
	}, {
		path: "/industry", //访问的路径
		component: industry //对应的组件
	},
	{
		path: "/about", //访问的路径
		component: about //对应的组件
	}
]

// 创建路由
const router = createRouter({
	// 访问方式
	// 
	/*
	    createWebHashHistory
	        bash: http://localhost:8080/#/
	        about:http://localhost:8080/#/about
	        原理：a标签锚点连接
	 */
	/*
        createWebHistory
            bash: http://localhost:8080/
            about:http://localhost:8080/about
            此种方式，需要后台配合做重定向，否则会出现404问题
            原理：H5 pushState()
    */
	history: createWebHistory(),
	// history: createWebHashHistory(),
	// 路由配置信息
	routes
})

export default router