<template>
  <div class="home" style="overflow-y: scroll">
    <div class="background">
      <div class="div1">
        <img src="../assets/images/Rectangle48.png" style="height: 100px; width: 100px;">
        <div>
          <div class="font1">LINX VALLEY</div>
        </div>
        <div>
          <div class="font2">工业互联行业的组态软件</div>
        </div>
        <div>
          <div class="font3">低代码PaaS开发平台</div>
          <div class="font3">业务前端&后端低代码开发PaaS平台</div>
        </div>
        <div>
          <div class="font4">23年低代码工业互联前端+后端业务低代码开发应用践行者</div>
        </div>
        <div>
          <div class="font5">
            23+years Low code business process PaaS development tools and configurable
            template for Industrial intelligent manufacturing
          </div>
        </div>
      </div>
    </div>

    <div class="background2">
      <div class="bg2div">
        <div class="bg2div-ul">
          <div>
            LINXPaaS
          </div>
          <div>
            为您节省50%-80%
          </div>
          <div>
            数字化工厂建设升级总成本
          </div>
        </div>
        <img class="bg2div-img" src="../assets/images/3351.png" />
      </div>

      <div class="bg2div">
        <div class="bg2div-ul">
          <div>
            低代码开发
          </div>
          <div>
            业务后端+前端 
          </div>
          <div>
            配置化MOM 软件系统模板
          </div>
        </div>
        <img class="bg2div-img" src="../assets/images/2261.png" />
      </div>

      <div class="bg2div">
        <div class="bg2div-ul">
          <div>
            多行业实际项目
          </div>
          <div>
            交付案例模板
          </div>
        </div>
        <img class="bg2div-img" src="../assets/images/4471.png" />
      </div>
    </div>

    <div class="background3">
      <div class="bg3div1"></div>
      <div class="bg3div2">
        <div class="bg3div3">
          <ul class="ul1">
            加速制造运营创新
          </ul>
          <ul>
            将新产品导入、制造过程管理自动化，为客户创造更快、更好的服务和商品。
          </ul>
        </div>
        <div class="bg3div4"></div>
      </div>
      <div class="bg3div5">
        <div class="bg3div6">
          <ul class="ul2">
            深化运营管理数字化
          </ul>
          <ul>
            为整个企业（包括供应商）创建流畅的信息流，提高制造运营效率。
          </ul>
        </div>
        <div class="bg3div7"></div>
      </div>
      <div class="bg3div8">
        <div class="bg3div9">
          <ul class="ul3">
            赋能员工，全员参与数字化
          </ul>
          <ul>
            简化运营数字化转型对于复杂IT技术的高度依赖,为员工配备简单的数字化工具，抓住每一个可以数字化的机会。
          </ul>
        </div>
        <div class="bg3div10"></div>
      </div>

      <div class="background4">
        <div class="bg4div1">
          <div class="bg4div2">
            <ul class="ul4">
              多行业实际应用案例
            </ul>
          </div>
        </div>

        <div class="bg4div3">
          <el-carousel type="card" class="cardstyle">
            <el-carousel-item v-for="(item, index) in bg4imglist" :key="index">
              <img :src="item.url" class="imgstyle" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <div class="background5">
        <div class="bg5div1">
          <div class="bg5div2">
            <ul class="ul6">
              庞大的头部客户群验证
            </ul>
          </div>
        </div>

        <div class="bg5div3">
          <ul class="ul7">
            <div
              class="swiper-container swiper-container-initialized swiper-container-horizontal"
            >
              <div
                class="swiper-wrapper"
                style="
                  transition-duration: 0ms;
                  transform: translate3d(-951.429px, 0px, 0px);
                "
              >
                <div
                  class="swiper-slide"
                  data-swiper-slide-index="0"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li1">
                    <img class="bg5img1" src="../assets/images/Group796.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="1"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li2">
                    <img class="bg5img2" src="../assets/images/Group795.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="2"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li3">
                    <img class="bg5img3" src="../assets/images/Group794.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="3"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li4">
                    <img class="bg5img4" src="../assets/images/Group793.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="4"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li5">
                    <img class="bg5img5" src="../assets/images/Group792.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="5"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li6">
                    <img class="bg5img6" src="../assets/images/Group791.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="6"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li7">
                    <img class="bg5img7" src="../assets/images/Group790.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="7"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li8">
                    <img class="bg5img8" src="../assets/images/Group789.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="8"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li9">
                    <img class="bg5img9" src="../assets/images/Group797.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="9"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li10">
                    <img class="bg5img10" src="../assets/images/Group798.png" />
                  </li>
                </div>

                <div
                  class="swiper-slide"
                  data-swiper-slide-index="10"
                  style="transition-duration: 0ms"
                >
                  <li class="bg5li11">
                    <img class="bg5img11" src="../assets/images/Group799.png" />
                  </li>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <div class="background6">
        <div class="bg6div1">
          <div class="bg6div2">
            <ul class="ul8">
              自主掌控数字化升级
            </ul>
          </div>
        </div>
        <div class="bg6div3"></div>
      </div>

      <!-- <div class="background7">
				<div class="bg7div1">
					不再需要资深开发工程师
				</div>
				
				<div class="bg_01_1">
					<div class="bg7div2">
						大幅降低数字化转型对于IT能力的依赖
					</div>
				</div>
				
			</div> -->
      <div class="background7">
        <div class="bg7div1">不再需要资深开发工程师</div>

        <div class="bg_01_1">
          <div class="bg7div2">让业务人员自主轻松构建运营数字化系统</div>
        </div>

        <div class="bg7div3">大幅降低数字化转型对于IT能力的依赖</div>
      </div>

      <div class="contact_us" @click="contactOnline()" alt="">
        <div class="contact_logo"></div>
        <div class="contact_font">在线咨询</div>
      </div>

      <!-- <div class="down_logo">
        <div class="down_arrow"></div>
      </div> -->

      <div v-show="showWindow" class="mask">
        <div class="win_dilog">
          <div class="dilog_left">
            <div class="dilog_font">留言板</div>
            <div class="dilog_xian"></div>
            <div class="dilog_remark">
              感谢您的关注，当前客服人员不在线，请填写一下您的信息，我们会尽快和您联系。
            </div>
          </div>
          <div class="dilog_right">
            <el-from>
              <el-row>
                <el-col>
                  <el-input
                    class="el_iput"
                    placeholder="请留言内容，我们会尽快与您联系。（必填）"
                    v-model="form.content"
                  ></el-input>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <el-input
                    class="el_iput2"
                    placeholder="姓名（必填）"
                    v-model="form.name"
                  ></el-input>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <el-input
                    class="el_iput3"
                    placeholder="请输入电话（必填）"
                    v-model="form.phonenumber"
                  ></el-input>
                </el-col>
              </el-row>

              <el-row>
                <el-col>
                  <el-input
                    class="el_iput4"
                    placeholder="公司（必填）"
                    v-model="form.company"
                  ></el-input>
                </el-col>
              </el-row>

              <el-row :span="24">
                <el-col>
                  <el-button @click="confirmClick" class="el_button"
                    ><span style="color: #ffffff">提交</span></el-button
                  >
                </el-col>

                <el-col>
                  <el-button @click="cancelClick" class="el_button1">取消</el-button>
                </el-col>
              </el-row>
            </el-from>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  </div>
</template>

<script>
// @ts-ignore
import Footer from "./foot.vue";

// @ts-ignore
import swiper from "swiper";

export default {
  components: { Footer },
  data() {
    return {
      caption: {},
      show: {},
      msg: {},

      form: {
        content: null,
        name: null,
        phonenumber: null,
        company: null,
      },

      showWindow: false,
      bg4imglist: [
        // @ts-ignore
        { id: 0, url: require("../assets/images/Group1240.png") },
        // @ts-ignore
        { id: 1, url: require("../assets/images/Group1241.png") },
        // @ts-ignore
        { id: 2, url: require("../assets/images/Group1242.png") },
        // @ts-ignore
        { id: 3, url: require("../assets/images/Group1248.png") },
        // @ts-ignore
        { id: 4, url: require("../assets/images/Group1244.png") },
        // @ts-ignore
        { id: 5, url: require("../assets/images/Group1245.png") },
        // @ts-ignore
        { id: 6, url: require("../assets/images/Group1246.png") },
        // @ts-ignore
        { id: 7, url: require("../assets/images/Group1247.png") },
      ],
    };
  },
  methods: {
    contactOnline() {
      console.log("弹出框");
      this.showWindow = true;
    },
    cancelClick() {
      this.showWindow = false;
    },
    confirmClick() {
      this.$emit("confirm");
    },
  },
  created: function () {
    this.$emit("footer", true);
  },
  mounted() {
    new swiper(".swiper-container", {
      loop: true,
      autoplay: true,
      speed: 1000,
      slidesPerView: 11,
      spaceBetween: 0,
      centeredSlides: true,
      watchSlidesProgress: true,
      on: {
        setTranslate: function () {
          var slides = this.slides;
          for (var i = 0; i < slides.length; i++) {
            var slide = slides.eq(i);
            var progress = slides[i].progress;
            slide.css({ opacity: "", background: "" });
            slide.transform(""); //清除样式
          }
        },
        setTransition: function (transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            slide.transition(transition);
          }
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
};
</script>

<style scoped>
@import "../css/index.css";

.cardstyle {
  top: 100px;
  left: 220px;
  width: 70%;
  height: 500px;
}

.imgstyle {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
</style>
