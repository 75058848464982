<template>
	<div class="indContain">
		<div class="partone">
			<div class="ind1">
				工业电气行业
			</div>
			<div class="ind2">
				MOM
			</div>
			<div class="ind3">
				解决方案
			</div>

			<div class="ind4">
				MOM Solutions for Industrial electrical industry
			</div>
		</div>
		<div class="partsecond">
			<div class="pslogo"></div>
			<div class="psfont">工业电气行业制造数字化解决方案引领者</div>
			<div class="imglist">
				<ul>
					<li class="lione"><img src="../assets/images/industry/bianyaqi.png"></li>
					<li class="litwo"><img src="../assets/images/industry/Frame523.png"></li>
					<li class="lithr"><img src="../assets/images/industry/Group512.png"></li>
				</ul>
				<ul>
					<li class="lifor"><img src="../assets/images/industry/gaodiya.png"></li>
					<li class="lifiv"><img src="../assets/images/industry/gaodiyamuxian.png"></li>
					<li class="lisix"><img src="../assets/images/industry/Group515.png"></li>
				</ul>
				<ul>
					<li class="lisev"><img src="../assets/images/industry/Frame524.png"></li>
					<li class="liegi"><img src="../assets/images/industry/pic8.png"></li>
					<li class="linin"><img src="../assets/images/industry/pic.png"></li>
				</ul>
				<div class="icon24"></div>
				<div class="icon25"></div>
				<div class="icon26">中压开关</div>
				<div class="icon27">Medium voltage switch</div>

				<div class="icon28"></div>
				<div class="icon29"></div>
				<div class="icon30">低压断路器</div>
				<div class="icon31">Low voltage circuit breaker</div>
				
				<div class="icon32"></div>
				<div class="icon33"></div>
				<div class="icon34">高低压母线</div>
				<div class="icon35">High and low voltage busbar</div>

				<div class="icon36"></div>
				<div class="icon37"></div>
				<div class="icon38">硅晶体太阳能</div>
				<div class="icon39">Silicon crystal solar energy</div>

				<div class="icon40"></div>
				<div class="icon41"></div>
				<div class="icon42">高低压配电柜</div>
				<div class="icon43">High and low voltage power distribution cabinet</div>

				<div class="icon44"></div>
				<div class="icon45"></div>
				<div class="icon46">真空断路器</div>
				<div class="icon47">Vacuum circuit breaker</div>

				<div class="icon48"></div>
				<div class="icon49"></div>
				<div class="icon50">高中压预制舱</div>
				<div class="icon51">High pressure prefabricated cabin</div>
			</div>
		</div>
		<div class="partthree">
			<div class="ptfont">
				灵娃MOM解决方案模板已经覆盖的工业电气产品
			</div>
		</div>

		<Footer></Footer>
	</div>
</template>

<script>
// @ts-ignore
import Footer from './foot.vue'

export default {
	components: { Footer },
	data() {
		return {}
	},
	created: function () {
		this.$emit('footer', true)
	}
};
</script>

<style>
.indContain {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	position: absolute;
}

.partone {
	width: 100%;
	height: 500px;
	background: url("../assets/images/industry/Rectangle569.png");
	background-size: 100% 100%;
}

.ind1 {
	width: 507px;
	height: 44px;
	font-size: 36px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 44px;
	position: absolute;
	margin-top: 150px;
	margin-left: 80px;
}

.ind2 {
	width: 507px;
	height: 44px;
	font-size: 36px;
	font-family: HarmonyOS Sans SC-Regular;
	font-weight: Regular;
	color: #048DFE;
	line-height: 44px;
	position: absolute;
	margin-top: 150px;
	margin-left: 240px;
}

.ind3 {
	width: 507px;
	height: 44px;
	font-size: 36px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 44px;
	position: absolute;
	margin-top: 150px;
	margin-left: 365px;
}

.ind4 {
	width: 410px;
	height: 24px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 16px;
	position: absolute;
	margin-top: 210px;
	margin-left: 180px;
}

.partsecond {
	width: 100%;
	height:900px;
	background: #FFFFFF;
	position: relative;
}

.pslogo {
	width: 48px;
	height: 8px;
	background: linear-gradient(-45deg, transparent 1px, #048DFE 20%);
	opacity: 1;
	position: absolute;
	margin: 50px 50%;
}

.psfont {
	width: 868px;
	height: 42px;
	font-size: 24px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #0D1721;
	line-height: 48px;
	position: absolute;
	margin-top: 70px;
	margin-left: 550px;
}

.imglist {
	display: flex;
	position: absolute;
	left: 180px;
}

.lione {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 200px;
	left: 100px;
}

.litwo {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 200px;
	left: 550px;
}


.lithr {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 200px;
	left: 1000px;
}

.lifor {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 400px;
	left: 50px;
}

.lifiv {
	width: 424px;
	height: 167px;
	position: absolute;
	top:400px;
	left: 550px;
}

.lisix {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 400px;
	left: 1000px;
}

.lisev {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 600px;
	left: 100px;
}

.liegi {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 600px;
	left: 550px;
}

.linin {
	width: 424px;
	height: 167px;
	position: absolute;
	top: 600px;
	left: 1000px;
}

.icon24 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/industry/icon24Default.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 300px;
	margin-left: 550px;
}

.icon25 {
	width: 29px;
	height: 21px;
	background: url("../assets/images/industry/Group805.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 320px;
	margin-left: 565px;
}

.icon26 {
	width: 74px;
	height: 25px;
	font-size: 18px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #1E2832;
	line-height: 24px;
	position: absolute;
	margin-top: 220px;
	margin-left: 565px;
}

.icon27 {
	width: 170px;
	height: 20px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	position: absolute;
	margin-top: 245px;
	margin-left: 560px;
}

.icon28 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/industry/icon24Default.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 300px;
	margin-left: 1000px;
}

.icon29 {
	width: 29px;
	height: 21px;
	background: url("../assets/images/industry/Group569.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 325px;
	margin-left: 1015px;
}

.icon30 {
	width: 94px;
	height: 25px;
	font-size: 18px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #1E2832;
	line-height: 24px;
	position: absolute;
	margin-top: 220px;
	margin-left: 1015px;
}

.icon31 {
	width: 200px;
	height: 20px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	position: absolute;
	margin-top: 245px;
	margin-left: 1010px;
}

.icon32 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/industry/icon24Default.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 500px;
	margin-left: 550px;
}

.icon33 {
	width: 29px;
	height: 21px;
	background: url("../assets/images/industry/Group572.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 525px;
	margin-left: 565px;
}

.icon34 {
	width: 94px;
	height: 25px;
	font-size: 18px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #1E2832;
	line-height: 24px;
	position: absolute;
	margin-top: 420px;
	margin-left: 565px;
}

.icon35 {
	width: 200px;
	height: 20px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	position: absolute;
	margin-top: 445px;
	margin-left: 565px;
}

.icon36 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/industry/icon24Default.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 500px;
	margin-left: 1000px;
}

.icon37 {
	width: 29px;
	height: 21px;
	background: url("../assets/images/industry/Group575.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 525px;
	margin-left: 1015px;
}

.icon38 {
	width: 120px;
	height: 25px;
	font-size: 18px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #1E2832;
	line-height: 24px;
	position: absolute;
	margin-top: 420px;
	margin-left: 1010px;
}

.icon39 {
	width: 200px;
	height: 20px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	position: absolute;
	margin-top: 445px;
	margin-left: 1005px;
}

.icon40 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/industry/icon24Default.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 700px;
	margin-left: 100px;
}

.icon41 {
	width: 29px;
	height: 21px;
	background: url("../assets/images/industry/Group576.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 725px;
	margin-left: 115px;
}

.icon42 {
	width: 120px;
	height: 25px;
	font-size: 18px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #1E2832;
	line-height: 24px;
	position: absolute;
	margin-top: 615px;
	margin-left: 110px;
}

.icon43 {
	width: 400px;
	height: 20px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	position: absolute;
	margin-top: 645px;
	margin-left: 80px;
}

.icon44 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/industry/icon24Default.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 700px;
	margin-left: 550px;
}

.icon45 {
	width: 29px;
	height: 21px;
	background: url("../assets/images/industry/Group804.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 725px;
	margin-left: 565px;
}

.icon46 {
	width: 120px;
	height: 25px;
	font-size: 18px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #1E2832;
	line-height: 24px;
	position: absolute;
	margin-top: 615px;
	margin-left: 556px;
}

.icon47 {
	width: 400px;
	height: 20px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	position: absolute;
	margin-top: 645px;
	margin-left: 446px;
}

.icon48 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/industry/icon24Default.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 700px;
	margin-left: 1000px;
}

.icon49 {
	width: 29px;
	height: 21px;
	background: url("../assets/images/industry/Group578.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 725px;
	margin-left: 1015px;
}

.icon50 {
	width: 120px;
	height: 25px;
	font-size: 18px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #1E2832;
	line-height: 24px;
	position: absolute;
	margin-top: 615px;
	margin-left: 1010px;
}

.icon51 {
	width: 400px;
	height: 20px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	position: absolute;
	margin-top: 645px;
	margin-left: 930px;
}

.partthree {
	width: 100%;
	height: 170px;
	background: url("../assets/images/industry/Frame669.png");
	background-size: 100% 100%;

}

.ptfont {
	width: 624px;
	height: 42px;
	font-size: 28px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 42px;
	position: absolute;
	margin-top: 70px;
	margin-left: 30%;
}

.imglist ul li {
	list-style-type: none;
}

</style>