<template>
	<div class="bgabout">
		<div class="about_1">
			<div class="remark">
				关于我们
			</div>
			<div class="remark2">
				About us
			</div>
		</div>

		<div class="about_2">
			<div class="remark3">
				上海灵娃智能科技有限公司创立于1999年，上海市高新技术企业，拥有完全自主知识产权低代码开发PaaS平台，自主软件开发编译器，并围绕平台开发了数十个应用软件产品。
			</div>
			<div class="remark4">
				基于平台，历经20年的深度耕耘，灵娃在工业电气、汽车外饰、汽车电子、军工航天、装备制造业、医疗器械、纺织成衣、光伏、模具制造、陶瓷材料、石油化工仓储等多个行业充分验证了平台的灵活开发能力及模板的实际可落地性。
			</div>
			<div class="remark5">
				实际案例覆盖了机加工、钣金冲压、铝合金压铸、注塑、机电装配、电子SMT等多种工艺类型，涉及：电气高压开关、高中压母线、变压器、低压开关、断路器、空气开关、电气铜材、电气桥架、开关柜、汽车内饰、汽车外饰、平面显示器、硬盘、路由器、网络防火墙、机顶盒、生物制品、医疗器械、高分子材料等多种产品的制造过程管理软件系统模板。
			</div>
		</div>

		<div class="about_3">
			<div class="logo1"></div>
			<div class="remark6">企业愿景</div>
			<div class="remark7">赋能个性化制造</div>

			<div class="logo2"></div>
			<div class="remark8">企业使命</div>
			<div class="remark81">让</div>
			<div class="remark82">您</div>
			<div class="remark83">的工艺设备技术人员轻松</div>
			<div class="remark84">为您实现数字化</div>
			<div class="remark85">升级</div>
		</div>

		<div class="about_4">
			<div class="remark9"></div>
			<div class="remark10">核心竞争力</div>
		</div>

		<div class="about_5">
			<div class="remark11">
				<div class="remark12"></div>
				<div class="abfont1">应用产品及模板</div>
				<div class="abfont11">深度</div>
				<div class="abfont2">
					从大型制造企业董事长、总经理、厂长视角出发，深度融合精益思想，以整体系统化运营为纲，结合实操管理和软件项目落地经验，以整体规划、分步实施的思想设计软件应用模板。灵娃长期坚持数字化是作为精益管理提升的手段而非目的的思想，持续不断努力让数字化真正为企业带来价值。
				</div>
				<div class="abfont3">
					以低代码开发平台为基础，灵娃深耕制造业数字化二十余年，有着覆盖央企、上市集团公司、外资五百强、民营中小微企业的实际数字化项目的落地经验，深度理解制造业运营管理过程中的无奈和痛点，集合精益制造思想、数据驱动精益及质量提升思想，细微精致雕琢制造企业生产运营的处处细节，为客户提供具备深度应用案例为基础的数字化解决方案模板。
				</div>
			</div>

			<div class="remark13">
				<div class="remark14">
					<div class="abfont4">开发平台工具的技术</div>
					<div class="abfont5">高度</div>
					<div class="abfont6">
						灵娃低代码平台，是国内少有的内嵌自主开发编译器的开发平台工具，正式商用20余年，经大量案例的磨练，经数十次大小持续迭代升级优化，系统的先进性、开放性、稳定性、可落地性、应用开发便利性，已经由市场实际案例充分支撑验证。
					</div>
				</div>
			</div>

			<div class="remark15">
				<div class="remark16"></div>
				<div class="abfont7">应用产品及模板</div>
				<div class="abfont8">广度</div>
				<div class="abfont9">
					历经工业互联领域20年的深度耕耘，灵娃工厂数字化应用模板应用在工业电气、汽车外饰、汽车电子、军工航天、装备制造业、医疗器械、纺织成衣、光伏、模具制造、陶瓷材料、石油化工仓储等多个行业实际交付了众多的数字化工厂项目，大量的实际案例覆盖了机加工、钣金冲压、铝合金压铸、注塑、机电装配、电子SMT等多种工艺类型，涉及：电气高压开关、高中压母线、变压器、低压开关、断路器、空气开关、电气铜材、电气桥架、开关柜、汽车内饰、汽车外饰、平面显示器、硬盘、路由器、网络防火墙、机顶盒、生物制品、医疗器械、高分子材料等各种产品的制造业务流程管理。
				</div>
			</div>
			<div class="remark17"></div>
		</div>

		<div class="about_6">
			<div class="about_6_img">

			</div>
			<!-- <div class="abimg1"></div>
			<div class="imgfont1">根据客户具象个性化需求 进行实用性开发定制及适用</div>
			<div class="abimg2"></div>
			<div class="abimg3"></div>
			<div class="abimg4"></div>
			<div class="abimg5"></div>
			<div class="abimg6"></div>
			<div class="abimg7"></div>
			<div class="abimg8"></div>
			<div class="imgfont2">根据实际交付型项目经验，梳理共性需求， 凝聚成应用软件产品，并持续升级</div> -->
		</div>

		<div class="about_7">

			<div class="remark18"></div>
			<div class="remark19">发展历程</div>

			<div class="remark20"></div>

			<div class="yuan"></div>
			<div class="about_01"></div>

			<div class="about_kuan_1">
				<div class="about_02">1999年</div>
				<div class="about_03">
					<div class="about_03_1">引进硅谷技术，开始自主知识产权的软件开发平台研发</div>
				</div>
			</div>

			<div class="about_xian_02"></div>
			<div class="about_kuan_2">
				<div class="about_06">2002年</div>
				<div class="about_05">
					<div class="about_05_1">施耐德配电和尤妮佳工厂基于"灵蛙管理"平台开发的"LINX MES"系统上线</div>
				</div>
			</div>

			<div class="about_xian_03"></div>
			<div class="about_kuan_3">
				<div class="about_07">2005年</div>
				<div class="about_08">
					<div class="about_08_1">自主"灵蛙管理"软件开发获"上海市创新基金" LINX WMS系统 V2.0 上线</div>
				</div>
			</div>

			<div class="about_xian_04"></div>
			<div class="about_kuan_4">
				<div class="about_09">2006年</div>
				<div class="about_10">
					<div class="about_10_1">"LINX MES"系统获得"国家级科技型中小企业技术创新资金"</div>
				</div>
			</div>

			<div class="about_xian_05"></div>
			<div class="about_kuan_5">
				<div class="about_11">2009年</div>
				<div class="about_12">
					<div class="about_12_1">"LINX MES系统 V2.0 上线</div>
				</div>
			</div>

			<div class="about_xian_06"></div>
			<div class="about_kuan_6">
				<div class="about_13">2010年</div>
				<div class="about_14">
					<div class="about_14_1">LINX MES 数字化精益看板上线</div>
				</div>
			</div>

			<div class="about_xian_07"></div>
			<div class="about_kuan_7">
				<div class="about_15">2012年</div>
				<div class="about_16">
					<div class="about_16_1">LINX MES 系统 V3.0 上线</div>
				</div>
			</div>

			
			<div class="about_xian_09"></div>
			<div class="about_kuan_9">
				<div class="about_19">2013年</div>
				<div class="about_20">
					<div class="about_20_1">LINX QMS 系统 V2.0 上线</div>
				</div>
			</div>

			<div class="about_xian_10"></div>
			<div class="about_kuan_10">
				<div class="about_21">2014年</div>
				<div class="about_22">
					<div class="about_22_1">LINX APS 商用客户正式上线</div>
				</div>
			</div>

				

			<div class="about_xian_11"></div>
			<div class="about_kuan_11">
				<div class="about_23">2016年</div>
				<div class="about_24">
					<div class="about_24_1">LINX QMS 系统 V3.0 上线</div>
				</div>
			</div>

			<div class="about_xian_12"></div>
			<div class="about_25">2017年</div>
			<div class="about_26">
					<div class="about_26_1">LINX MES 集团级工厂客户（>20间工厂）客户上线</div>
			</div>
		
			<div class="about_xian_13"></div>
			<div class="about_27">2018年</div>
			<div class="about_28">
				<div class="about_28_1">LINX EAM 系统V3.0 发布</div>
			</div>

			<div class="about_xian_14"></div>
			<div class="about_29">2019年</div>
			<div class="about_30">
				<div class="about_30_1">LINX MOM 平台系统发布 9大精益工具上线</div>
			</div>

			
			<div class="about_xian_15"></div>
			<div class="about_31">2020年</div>
			<div class="about_32">
				<div class="about_32_1">LINX MOM 精益管理工具V1.0 发布 包含9大精益工具</div>
			</div>

			<div class="about_xian_16"></div>
			<div class="about_33">2021年</div>
			<div class="about_34">
				<div class="about_34_1">LINX 低代码开发平台V4.0商用交付</div>
			</div>

			<div class="about_xian_17"></div>
			<div class="about_arrow"></div>
		</div>

		<!-- <div class="about_8">
			<div class="about_left"></div>

			<div class="ab_img_list_1">
				<div class="ab_img_1"></div>
			</div>

			<div class="ab_img_list_2">
				<div class="ab_img_2"></div>
			</div>

			<div class="ab_img_list_3">
				<div class="ab_img_3"></div>
			</div>

			<div class="ab_img_list_4">
				<div class="ab_img_4"></div>
			</div>

			<div class="about_right"></div>

		</div> -->
		
		<div class="swiper-container swiper-container-initialized swiper-container-horizontal" id="about_8">
			<div class="swiper-wrapper" style="transition-duration: 0ms; transform: translate3d(-951.429px, 0px, 0px);">
				
				<div class="swiper-slide" data-swiper-slide-index="0" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_1"></div>
				</div>

				<div class="swiper-slide" data-swiper-slide-index="1" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_2"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="2" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_3"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="3" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_4"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="4" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_5"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="5" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_6"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="6" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_7"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="7" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_8"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="8" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_9"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="9" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_10"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="10" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_11"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="11" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_12"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="12" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_13"></div>
				</div>
				<div class="swiper-slide" data-swiper-slide-index="13" style="width: 321px; transition-duration: 0ms;">
					<div class="ab_img_14"></div>
				</div>
			</div>
			<div class="swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide"></div>
			<div class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide"></div>
			<!-- <div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets">
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 1"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 2"></span>
				<span class="swiper-pagination-bullet swiper-pagination-bullet-active" tabindex="0" role="button" aria-label="Go to slide 3"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 4"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 5"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 6"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 7"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 8"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 9"></span>
				<span class="swiper-pagination-bullet" tabindex="0" role="button" aria-label="Go to slide 10"></span>
			</div>
			<span class="swiper-notification" aria-live="assertive" aria-atomic="true"></span> -->
		</div>

		<div class="partbottom">二十多年低代码平台应用，核心专注工业互联</div>
		<Footer></Footer>
	</div>
</template>

<script>
// @ts-ignore
import Footer from './foot.vue'

// @ts-ignore
import swiper from 'swiper'

export default {
	components: { Footer },
	data() {
		return {}
	},
	created: function () {
		this.$emit('footer', true)
	},
	mounted(){
	// 	new swiper(".swiper-container", 
	// 	{loop: true, 
	// 		// 循环模式选项// 如果需要分页器
	// 		pagination: {el: ".swiper-pagination",},
	// 		// 如果需要前进后退按钮
	// 		navigation: {nextEl: ".swiper-button-next",prevEl: ".swiper-button-prev",}});
	// 
	var effect = 0

	new swiper('.swiper-container', {
	loop: true,
	speed: 2500,
    slidesPerView: 4,
    spaceBetween: 30,
	centeredSlides : true,
	watchSlidesProgress : true,
	on: {
	setTranslate: function(){
			var slides = this.slides
			for(var i=0; i< slides.length; i++){
				var slide = slides.eq(i)
				var progress = slides[i].progress
				slide.css({'opacity': '','background': ''});slide.transform('');//清除样式
					
				// if(effect == 1){
				// 	slide.transform('scale('+(1 - Math.abs(progress)/8)+')');  
				// }else if(effect == 2){
				// 	slide.css('opacity',(1-Math.abs(progress)/6));
				// 	slide.transform('translate3d(0,'+ Math.abs(progress)*20+'px, 0)');  
				// }
				// else if(effect == 3){
				// 	slide.transform('rotate('+ progress*30+'deg)');  
				// }else if(effect == 4){
				// 	slide.css('background','rgba('+ (255 - Math.abs(progress)*20) +','+ (127 + progress*32) +',' + Math.abs(progress)*64 + ')');
				// }
					
			}	
		},
		setTransition: function(transition) {
			for (var i = 0; i < this.slides.length; i++) {
				var slide = this.slides.eq(i)
				slide.transition(transition);
			}
		},
	},
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });
	
	var pe = document.getElementById('progressEffect');
	if (pe) {
		// @ts-ignore
		pe.onchange = function(){ 
		// @ts-ignore
		effect = this.value
			// @ts-ignore
			swiper.update();	
		}
	}

},
	
};
</script>

<style scoped>
.bgabout {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	position: absolute;
	
}

.about_1 {
	width: 100%;
	height: 500px;
	background: url("../assets/images/about/banner.png");
	background-size: 100% 100%;
	
}

.about_2 {
	width: 100%;
	height: 300px;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
	font-size: 18px;
}

.about_3 {
	width: 100%;
	height: 180px;
	background: #F3F7FB;
	opacity: 1;
	font-size: 22px;
}

.about_4 {
	width: 100%;
	height: 90px;
	background: #FFFFFF;
	opacity: 1;

}

.about_5 {
	width: 100%;
	height: 696px;
	background: url("../assets/images/about/background.png");
	background-size: 100% 100%;
	padding: 0px 300px;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.about_6 {
	width: 100%;
	height: 800px;
	background: url("../assets/images/about/Group1224.png");
	background-size: 100% 100%;
}

.about_7 {
	width: 100%;
	height:540px;
	background: #FFFFFF;
	border-radius: 0px 0px 0px 0px;
}

#about_8 {
	width: 100%;
	height: 430px;
	background: url("../assets/images/about/Rectangle709.png");
	background-size: 100% 100%;
}

.swiper-wrapper /deep/ .swiper-slide {
	width: 321px;
	height: 328px;
	background-color: #FFFFFF;
	margin-top: 40px;
}

.remark {
	width: 361px;
	height: 44px;
	font-size: 36px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 44px;
	padding-top: 180px;
	padding-left: 200px;
	white-space: nowrap;
}

.remark2 {
	width: 370px;
	height: 24px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #A0ACBB;
	line-height: 20px;
	padding-top: 50px;
	padding-left: 120px;
	white-space: nowrap;
}



.remark3 {
	width: 1322px;
	height: 122px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	padding-top: 50px;
	padding-left: 200px;
	text-align: left;
}

.remark4 {
	width: 1322px;
	height: 122px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: Medium;
	color: #0D1721;
	padding-left: 200px;
	text-align: left;
}

.remark5 {
	width: 1322px;
	height: 122px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: Medium;
	color: #0D1721;
	margin-top: -60px;
	padding-left: 200px;
	text-align: left;
}



.logo1 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/about/Group619.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 60px;
	margin-left: 280px;
}

.remark6 {
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #0D1721;
	position: absolute;
	margin-top: 60px;
	margin-left: 340px;
}

.remark7 {
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	position: absolute;
	margin-top: 90px;
	margin-left: 340px;
}

.logo2 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/about/icon64.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 60px;
	margin-left: 780px;
}

.remark8 {
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #0D1721;
	position: absolute;
	margin-top: 60px;
	margin-left: 830px;
}

.remark81 {
	font-family: HarmonyOS Sans SC-Bold;
	font-weight: bold;
	color: #0D1721;
	position: absolute;
	margin-top: 90px;
	margin-left: 830px;
}

.remark82 {
	font-family: HarmonyOS Sans SC-Bold;
	font-weight: bold;
	color: #0D1721;
	position: absolute;
	font-size: 26px;
	margin-top: 88px;
	margin-left: 855px;
}

.remark83 {
	font-family: HarmonyOS Sans SC-Bold;
	font-weight: bold;
	color: #0D1721;
	position: absolute;
	margin-top: 90px;
	margin-left: 882px;
}

.remark84 {
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	font-size: 26px;
	color: #0D1721;
	position: absolute;
	margin-top: 88px;
	margin-left: 1128px;
}

.remark85 {
	font-family: HarmonyOS Sans SC-Bold;
	font-weight: bold;
	color: #0D1721;
	position: absolute;
	margin-top: 90px;
	margin-left: 1310px;
}


.remark9 {
	width: 48px;
	height: 8px;
	background: linear-gradient(-45deg, transparent 1px, #048DFE 20%);
	opacity: 1;
	margin: 50px 50%;
}

.remark10 {
	width: 550px;
	height: 42px;
	font-size: 24px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #000000;
	line-height: 48px;
	margin: -40px 37%;
}



.remark11 {
	width: 311px;
	height: 503px;
	background: linear-gradient(180deg, rgba(21, 86, 139, 0.38) 0%, rgba(255, 255, 255, 0) 100%);
	box-shadow: 0px 4px 12px 0px rgba(10, 65, 70, 0.24);
	border-radius: 4px 4px 4px 4px;
}

.remark12 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/about/Group633.png");
	background-size: 100% 100%;
	position: absolute;
	margin-left: 20px;

}

.abfont1 {
	width: 164px;
	height: 28px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 24px;
	position: absolute;
	margin-top: 50px;
	margin-left: 10px;
}

.abfont11 {
	width: 164px;
	height: 28px;
	font-size: 20px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 24px;
	position: absolute;
	margin-top: 50px;
	margin-left: 90px;
}

.abfont2 {
	width: 263px;
	height: 340px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 20px;
	position: absolute;
	margin-top: 90px;
	margin-left: 35px;
	text-align: left;
}

.abfont3 {
	width: 263px;
	height: 340px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 20px;
	position: absolute;
	margin-top: 250px;
	margin-left: 35px;
	text-align: left;
}

.remark13 {
	width: 312px;
	height: 461px;
	background: linear-gradient(180deg, rgba(21, 86, 139, 0.38) 0%, rgba(255, 255, 255, 0) 100%);
	box-shadow: 0px 4px 12px 0px rgba(10, 65, 70, 0.24);
	border-radius: 4px 4px 4px 4px;
}

.remark14 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/about/Group938.png");
	background-size: 100% 100%;
	position: absolute;
	margin-left: 20px;
}

.abfont4 {
	width: 164px;
	height: 28px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 24px;
	position: absolute;
	margin-top: 60px;
	margin-left: 10px;
}

.abfont5 {
	width: 164px;
	height: 28px;
	font-size: 20px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 24px;
	position: absolute;
	margin-top: 60px;
	margin-left: 105px;
}

.abfont6 {
	width: 263px;
	height: 340px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 20px;
	position: absolute;
	margin-top: 100px;
	margin-left: 16px;
	text-align: left;
}

.remark15 {
	width: 312px;
	height: 503px;
	background: linear-gradient(180deg, rgba(21, 86, 139, 0.38) 0%, rgba(255, 255, 255, 0) 100%);
	box-shadow: 0px 4px 12px 0px rgba(10, 65, 70, 0.24);
	border-radius: 4px 4px 4px 4px;
}

.remark16 {
	width: 64px;
	height: 64px;
	background: url("../assets/images/about/Group636.png");
	background-size: 100% 100%;
	position: absolute;
	margin-left: 20px;
}

.abfont7 {
	width: 164px;
	height: 28px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 24px;
	position: absolute;
	margin-top: 60px;
	margin-left: 10px;
}

.abfont8 {
	width: 164px;
	height: 28px;
	font-size: 20px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #FFFFFF;
	line-height: 24px;
	position: absolute;
	margin-top: 60px;
	margin-left: 87px;
}

.abfont9 {
	width: 263px;
	height: 340px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 20px;
	position: absolute;
	margin-top: 100px;
	margin-left: 30px;
	text-align: left;
}

.remark17 {
	width: 1100px;
	height: 102px;
	background: url("../assets/images/about/background2.png");
	position: absolute;
	margin: 500px auto 0px;
	background-size: 100% 100%;
	opacity: 1;
	border: 0px dashed #A0ACBB;
}

.about_6_img {
	width: 1100px;
	height: 1100px;
	background: url("../assets/images/about/aboutus.png");
	background-size: 100% 100%;
	position: relative;
    top: -120px;
    left: -150px;
}



.abimg1 {
	width: 136px;
	height: 137px;
	background: url("../assets/images/about/Group1166.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 80px;
	margin-left: 440px;
}

.imgfont1 {
	width: 137px;
	height: 60px;
	font-size: 10px;
	font-family: HarmonyOS Sans SC-Regular;
	font-weight: Regular;
	color: #d6d6d6d5;
	line-height: 20px;
	position: absolute;
	margin-top: 230px;
	margin-left: 430px;
}

.abimg2 {
	width: 136px;
	height: 137px;
	background: url("../assets/images/about/Group1144.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 320px;
	margin-left: 440px;
}

.abimg3 {
	width: 90px;
	height: 90px;
	background: url("../assets/images/about/Linuxlogo.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 350px;
	margin-left: 460px;
}

.abimg4 {
	width: 166px;
	height: 195px;
	background: url("../assets/images/about/Group1162.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 460px;
	margin-left: 240px;
}

.abimg5 {
	width: 137px;
	height: 137px;
	background: url("../assets/images/about/Group1143.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 460px;
	margin-left: 640px;
}

.abimg6 {
	width: 137px;
	height: 137px;
	background: url("../assets/images/about/Group1141.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 260px;
	margin-left: 280px;
}

.abimg7 {
	width: 137px;
	height: 137px;
	background: url("../assets/images/about/Group1140.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 260px;
	margin-left: 580px;
}

.abimg8 {
	width: 157px;
	height: 157px;
	background: url("../assets/images/about/Group1139.png");
	background-size: 100% 100%;
	position: absolute;
	margin-top: 460px;
	margin-left: 450px;
}

.imgfont2 {
	width: 240px;
	height: 60px;
	font-size: 10px;
	font-family: HarmonyOS Sans SC-Regular;
	font-weight: Regular;
	color: #d6d6d6d5;
	line-height: 20px;
	position: absolute;
	margin-top: 590px;
	margin-left: 600px;
}



.remark18 {
	width: 48px;
	height: 8px;
	background: linear-gradient(-45deg, transparent 1px, #048DFE 20%);
	opacity: 1;
	margin: 40px 50%;
}

.remark19 {
	width: 550px;
	height: 42px;
	font-size: 28px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: #000000;
	line-height: 48px;
	position: relative;
	top: -35px;
	left: 705px;

}

.remark20 {
	width: 24px;
	height: 24px;
	background: url("../assets/images/about/Ellipse215.png");
	background-size: 100% 100%;
	margin: 180px 200px;
}

.yuan {
	width: 24px;
	height: 24px;
	background: #15568B;
	border-radius: 50%;
	position: relative;
	top: -320px;
	left: 200px;
}


.about_01 {
	width: 64px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -333px;
	left: 220px;
}


.about_kuan_1 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_02 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -476px;
	left: 485px;
}

.about_kuan_2 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_03 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -618px;
	left: 720px;
}

.about_kuan_3 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_04 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -761px;
	left: 955px;
}

.about_kuan_4 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_05 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -904px;
	left: 1190px;
}

.about_kuan_5 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_06 {
	width: 60px;
	/*半圆的宽度*/
	height: 130px;
	/*半圆的高度*/
	border-radius: 0px 60px 60px 0px;
	border: 3px solid #15568B;
	/*设置边框*/
	position: relative;
	border-left: none;
	top: -1047px;
	left: 1425px;
}

.about_kuan_6 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_07 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -1194px;
	left: 1190px;
}

.about_kuan_7 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

/* .about_kuan_8 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
} */

.about_xian_09 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -1340px;
	left: 955px;
}

.about_kuan_9 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_10 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -1483px;
	left: 720px;
}

.about_kuan_10 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

.about_xian_11 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -1623px;
	left: 485px;
}

.about_kuan_11 {
	width: 230px;
	height: 140px;
	position: relative;
	top: -325px;
	left: 200px;
}

/* .about_kuan_12 {
	width: 230px;
	height: 140px;
	position: relative;
	top: 11225px;
	left: 200px;
} */

.about_kuan_13 {
	width: 230px;
	height: 140px;
	position: relative;
}

.about_kuan_14 {
	width: 230px;
	height: 140px;
	position: relative;
}

.about_kuan_15 {
	width: 230px;
	height: 140px;
	position: relative;
}

.about_kuan_16 {
	width: 230px;
	height: 140px;
	position: relative;
}

.about_02 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -90px;
	left: 90px;
}

.about_03 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -80px;
	left: 85px;
}

.about_03_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}


.about_05 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -225px;
	left: 320px;
}

.about_05_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_06 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -235px;
	left: 320px;
}



.about_07 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -380px;
	left: 555px;
}

.about_08 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -370px;
	left: 555px;
}

.about_08_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}


.about_09 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -525px;
	left: 790px;
}

.about_10 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -515px;
	left: 790px;
}

.about_10_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}



.about_11 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -675px;
	left: 1030px;
}

.about_12 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -660px;
	left: 1025px;
}

.about_12_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}



.about_13 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -805px;
	left: 1030px;
}

.about_14 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -800px;
	left: 1025px;
}

.about_14_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}



.about_15 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -950px;
	left: 790px;
}

.about_16 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -945px;
	left: 790px;
}

.about_16_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_xian_08 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -1020px;
	left: 755px;
}

.about_17 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -1090px;
	left: 555px;
}

.about_18 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -1090px;
	left: 555px;
}

.about_18_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}




.about_19 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -1090px;
	left: 555px;
}

.about_20 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -1090px;
	left: 555px;
}

.about_20_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}



.about_21 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -1235px;
	left: 320px;
}

.about_22 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -1235px;
	left: 320px;
}

.about_22_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}



.about_23 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -1375px;
	left: 85px;
}

.about_24 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -1375px;
	left: 85px;
}

.about_24_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_xian_12 {
	width: 60px;
	height: 130px;
	border-radius: 60px 0px 0px 60px;
	border: 3px solid #15568B;
	position: relative;
	border-right: none;
	top: -1773px;
	left: 225px;
}

.about_25 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -1840px;
	left: 290px;
}

.about_26 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -1840px;
	left: 285px;
}

.about_26_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_xian_13 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -1885px;
	left: 485px;
}

.about_27 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -1960px;
	left: 520px;
}

.about_28 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -1960px;
	left: 520px;
}

.about_28_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_xian_14 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -2005px;
	left: 720px;
}

.about_29 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -2080px;
	left: 755px;
}

.about_30 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -2080px;
	left: 755px;
}

.about_30_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_xian_15 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -2125px;
	left: 955px;
}

.about_31 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -2200px;
	left: 990px;
}

.about_32 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -2200px;
	left: 990px;
}

.about_32_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_xian_16 {
	width: 34px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -2244px;
	left: 1190px;
}

.about_33 {
	width: 57px;
	height: 24px;
	font-size: 16px;
	font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
	font-weight: bold;
	color: #0D1721;
	line-height: 24px;
	position: relative;
	top: -2324px;
	left: 1225px;
}

.about_34 {
	width: 200px;
	height: 92px;
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%);
	box-shadow: 0px 4px 12px 0px rgba(228, 228, 228, 0.24);
	border-radius: 4px 4px 4px 4px;
	opacity: 1;
	border: 1px solid #E3E3E3;
	position: relative;
	top: -2324px;
	left: 1225px;
}

.about_34_1 {
	width: 166px;
	height: 60px;
	font-size: 14px;
	font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
	font-weight: 400;
	color: rgba(13, 23, 33, 0.4);
	line-height: 20px;
	text-align: left;
	position: relative;
	left: 20px;
	top: 10px;
}

.about_xian_17 {
	width: 44px;
	height: 3px;
	background: #15568B;
	border-radius: 0;
	position: relative;
	top: -2363px;
	left: 1425px;
}

.about_arrow {
	width: 18px;
	height: 18px;
	position: relative;
	top: -2374px;
	left: 1469px;
	border-top: 10px solid transparent;
	border-bottom: 10px solid transparent;
	border-left: 10px solid #15568B;
}




.about_left {
	width: 24px;
	height: 24px;
	background: url("../assets/images/about/Group235.png");
	background-size: 100% 100%;
	position: relative;
	top: 225px;
	left: 100px;
}

.ab_img_list_1 {
	width: 321px;
	height: 328px;
	position: relative;
	background-color: #FFFFFF;
	top: 50px;
	left: 150px;

}

.ab_img_1 {
	width: 321px;
	height: 328px;
	background: url("../assets/images/about/rongyu1.png");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 0px;
	margin-left: 90px;
}

.ab_img_list_2 {
	width: 321px;
	height: 328px;
	position: relative;
	background-color: #FFFFFF;
	top: -280px;
	left: 510px;

}

.ab_img_2 {
	width: 321px;
	height: 328px;
	background: url("../assets/images/about/rongyu2.png");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 0px;
	margin-left: 90px;
}

.ab_img_list_3 {
	width: 321px;
	height: 328px;
	position: relative;
	background-color: #FFFFFF;
	top: -610px;
	left: 870px;

}

.ab_img_3 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ1.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}

.ab_img_list_4 {
	width: 321px;
	height: 328px;
	position: relative;
	background-color: #FFFFFF;
	top: -940px;
	left: 1230px;

}

.ab_img_4 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ2.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}

.ab_img_5 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ3.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}

.ab_img_6 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ4.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_7 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ5.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_8 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ6.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_9 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ7.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_10 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ8.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_11 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ9.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_12 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ10.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_13 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ11.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}


.ab_img_14 {
	width: 225px;
	height: 300px;
	background: url("../assets/images/about/RZ12.jpg");
	background-size: 100% 100%;
	/* position: relative; */
	/* left: 50px;
	top: 80px; */
	margin-top: 10px;
	margin-left: 120px;
}

.about_right {
	width: 24px;
	height: 24px;
	background: url("../assets/images/about/Group234.png");
	background-size: 100% 100%;
	position: relative;
	top: -1110px;
	left: 1580px;
}

.partbottom {
	width: 100%;
	height: 190px;
	line-height: 190px;
	background: url("../assets/images/industry/Frame669.png");
	background-size: 100% 100%;
	font-size: 40px;
	color: #FFFFFF;
}

</style>