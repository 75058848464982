<template>
  <div class="lowcontain">
    <div class="lowpartone">
      <div class="low_partone_font1">灵娃低代码PaaS平台</div>
      <div class="low_partone_font2">LINX Low-code Paas platform</div>
    </div>

    <div class="lowparttwo">
      <div class="lowlogo"></div>
      <div class="lowlogo2">
        <span class="lowfont">20年+</span>
        <div class="lowfont2">低代码跨领域多种软件实际案例验证</div>
      </div>
      <div class="lowtitle">
        <div
          class="lowtext1"
          @mouseenter="changeActive($event)"
          @mouseleave="removeActive($event)"
        >
          <span>OA</span>
        </div>
        <div
          class="lowtext2"
          @mouseenter="changeActive2($event)"
          @mouseleave="removeActive2($event)"
        >
          <span>HRM</span>
        </div>
        <div
          class="lowtext3"
          @mouseenter="changeActive3($event)"
          @mouseleave="removeActive3($event)"
        >
          <span>CRM</span>
        </div>
        <div
          class="lowtext4"
          @mouseenter="changeActive4($event)"
          @mouseleave="removeActive4($event)"
        >
          <span>ERP</span>
        </div>
        <div
          class="lowtext5"
          @mouseenter="changeActive5($event)"
          @mouseleave="removeActive5($event)"
        >
          <span>MES</span>
        </div>
        <div
          class="lowtext6"
          @mouseenter="changeActive6($event)"
          @mouseleave="removeActive6($event)"
        >
          <span>手术门诊预约系统</span>
        </div>
      </div>

      <div class="lowlogo3">
        <div class="lowfont3">LINXPaaS为您提供适应不同行业和工艺类型的应用模板</div>
      </div>

      <div class="lowtitle2">
        <!-- <div class="lowleft" @click="moveLeft"></div> -->
        <div
          class="img_list"
          v-for="(item, index) in arrLow"
          :key="index"
        >
          <div>
            <img class="img" :src="item.image" />
            <div :class="item.cx">{{ item.text }}</div>
          </div>
        </div>
        <!-- <div class="lowright" @click="moveRight"></div> -->
      </div>
    </div>

    <div class="lowpartthree">
      <div class="lowdiv1">已验证交付的软件系统</div>
        <div class="lowdiv2-3">
          <div class="lowdiv2"></div>
          <div class="lowdiv3">
            <div class="kuan" v-for="(x,i) in lowdiv" :key="i">
              <img class="tu" :src="x.image" />
              <div class="zi">{{ x.title }}</div>
            </div>
          </div>
        </div>
        

      <div class="lowzi2">已验证的工艺流程</div>
      <div
        class="swiper-container swiper-container-initialized swiper-container-horizontal"
        style="height: 400px; width: 100%"
      >
        <div
          class="swiper-wrapper"
          style="
            transition-duration: 0ms;
            transform: translate3d(-951.429px, 0px, 0px);
            width: 100%;
            height: 400px;
          "
        >
          <div
            class="swiper-slide"
            :data-swiper-slide-index="`${index}`"
            style="transition-duration: 0ms"
            v-for="(item, index) in lowimglist"
            :key="index"
          >
            <div :class="item.className">
              <img :src="item.urlImg" />

              <div class="low_42_1_div">
                <div :class="`${item.className}_logo`"></div>
                <div :class="`${item.className}_font`">{{ item.text }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="lowpartfour">
      <div class="lowlogo4">
        <div class="lowfont4">LINXPaaS将为您创造的价值</div>
      </div>

      <div class="low4div1">
        <div class="low4img1"></div>
        <div class="low4title1">精益管理工具</div>
        <div class="low4context1">
          ANDON暗灯系统，E-CALL电子叫料， Training培训管理系统，Suggestion合理化建议
          ，E-SOP电子操作指导书，
          SPC统计过程控制系统，LayorAudit分层审核管理系统，CPC变更点管理系统
          ，LeanSPF精益现场管理。
        </div>
      </div>

      <div class="low4div2">
        <div class="low4img2"></div>
        <div class="low4title2">MOM 系统</div>
        <div class="low4context2">
          MES制造执行系统，APS高级计划排程系统，WMS仓储管理系统，EAM设备|工装|检具|模具管理系统，
          QMS质量管理系统，EMS能源管理系统等主要数字化工厂的软件系统。
          根据实际现场情况，只需进行简单调整适配就可以完成适用于不同工厂，车间，产线的软件应用。
        </div>
      </div>

      <div class="low4div3">
        <div class="low4img3"></div>
        <div class="low4title3">数据采集工具</div>
        <div class="low4context3">
          内嵌1000+种数据采集通讯协议，在实际使用中仅需要进行通讯和地址配置，
          就可以完成数据采集。
        </div>
      </div>

      <div class="lowlogo5">
        <div class="lowremark">
          <span class="low5font2">20年+</span>
        </div>
        <div class="low5context">我们只为做好供您快速编程的工具</div>
      </div>

      <div class="low5backimg"></div>
      <div class="low5back2">
        <ul>
          <li>
            <img src="../assets/images/lowpass/Component2.png" />
            <span>"无"开发编程经验要求</span>
          </li>
          <li>
            <img src="../assets/images/lowpass/Component3.png" />
            <span>业务及数据库程序自动生成</span>
          </li>
          <li>
            <img src="../assets/images/lowpass/Component4.png" />
            <span>权限控制可达字段</span>
          </li>
          <li>
            <img src="../assets/images/lowpass/Component5.png" />
            <span>"Office"式详尽帮助</span>
          </li>
          <li>
            <img src="../assets/images/lowpass/Component6.png" />
            <span>变更自动完整记录</span>
          </li>
          <li>
            <img src="../assets/images/lowpass/Component7.png" />
            <span>一次开发同步生成CS和BS系统</span>
          </li>
          <li>
            <img src="../assets/images/lowpass/Component8.png" />
            <span>内嵌自主开发编译器</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="lowpartfive">
      <div class="low5frame1"></div>
      <div class="low5frame2"></div>
      <div class="low5frame3"></div>
    </div>

    <div class="lowpartsix">
      <div class="lowlogo6">
        <div class="lowfont6">全面详尽的“Office” 式帮助文档</div>
      </div>
      <div class="low6img"></div>

      <div class="low6div">
        <div class="low6div1" v-for="(x,i) in low6div" :key="i">
          <img class="low6img1" :src="x.image" />
          <div>{{ x.title }}</div>
        </div>
      </div>
      
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// @ts-ignore
import { ref } from "vue";
// @ts-ignore
import Footer from "./foot.vue";

// @ts-ignore
import swiper from "swiper";

export default {
  components: { Footer },
  data() {
    return {
      clickNum: 0,

      divShow: true,
      divShow1: true,
      divShow2: true,
      divShow3: true,
      divShow4: true,
      divShow5: true,

      arrLow: [
        {
          className: "img_list",
          image: require("../assets/images/lowpass/Group767.png"),
          text: "汽车零部件行业",
          index: 0,
          isShow: true,
        },
        {
          className: "img_list",
          image: require("../assets/images/lowpass/Group767_1.png"),
          text: "通讯行业",
          index: 1,
          isShow: true,
        },
        {
          className: "img_list",
          image: require("../assets/images/lowpass/Group768.png"),
          text: "服装行业",
          index: 2,
          isShow: true,
        },

        {
          className: "img_list",
          image: require("../assets/images/lowpass/Group769.png"),
          text: "自动化行业",
          index: 3,
          isShow: true,
        },

        {
          className: "img_list",
          image: require("../assets/images/lowpass/Group770.png"),
          text: "半导体行业",
          index: 4,
          isShow: true,
        },

        {
          className: "img_list",
          image: require("../assets/images/lowpass/Group771.png"),
          text: "危化仓储行业",
          index: 5,
          isShow: true,
        },
      ],

      lowimglist: [
        {
          className: "low_42_1",
          text: "焊接工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group257.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group257@2x.png"),
        },
        {
          className: "low_42_2",
          text: "SMT工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group258.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group258@2x.png"),
        },
        {
          className: "low_42_3",
          text: "注塑工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group259.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group259@2x.png"),
        },
        {
          className: "low_42_4",
          text: "机加工工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group260.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group260@2x.png"),
        },
        {
          className: "low_42_5",
          text: "冲压工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group261.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group261@2x.png"),
        },
        {
          className: "low_42_6",
          text: "半导体工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group262.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group262@2x.png"),
        },
        {
          className: "low_42_7",
          text: "电镀工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group263.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group263@2x.png"),
        },
        {
          className: "low_42_8",
          text: "电子装配工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group264.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group264@2x.png"),
        },
        {
          className: "low_42_9",
          text: "表面处理工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group265.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group265@2x.png"),
        },
        {
          className: "low_42_10",
          text: "机电装配工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group266.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group266@2x.png"),
        },
        {
          className: "low_42_11",
          text: "钣金、折弯冲压工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group267.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group267@2x.png"),
        },
      ],

      lowdiv: [
        { 
          title: '制造执行系统',
          image: require("../assets/images/lowpass/Component12.png")},
        {title: '质量管理系统',image: require("../assets/images/lowpass/Component13.png")},
        {title: '仓储管理系统',image: require("../assets/images/lowpass/Component14.png")},
        {title: '设备管理系统',image: require("../assets/images/lowpass/Component15.png")},
        {title: '防窜货管理系统',image: require("../assets/images/lowpass/Component16.png")},
        {title: '高级计划排程系统',image: require("../assets/images/lowpass/Component17.png")},
      ],

      low6div: [
        {title: '安装和设置',image: require("../assets/images/lowpass/Group1242.png")},
        {title: '用户管理',image: require("../assets/images/lowpass/user.png")},
        {title: '宏政策管理',image: require("../assets/images/lowpass/remove-file.png")},
        {title: '学习指南',image: require("../assets/images/lowpass/Group1236.png")},
        {title: '常见问题',image: require("../assets/images/lowpass/bookmark.png")},
        {title: '自动编程',image: require("../assets/images/lowpass/remove-file.png")},

      ]
    };
  },
  methods: {
    changeActive(e) {
      e.currentTarget.className = "lowtext11";
    },
    removeActive(e) {
      e.currentTarget.className = "lowtext1";
    },
    changeActive2(e) {
      e.currentTarget.className = "lowtext21";
    },
    removeActive2(e) {
      e.currentTarget.className = "lowtext2";
    },
    changeActive3(e) {
      e.currentTarget.className = "lowtext31";
    },
    removeActive3(e) {
      e.currentTarget.className = "lowtext3";
    },
    changeActive4(e) {
      e.currentTarget.className = "lowtext41";
    },
    removeActive4(e) {
      e.currentTarget.className = "lowtext4";
    },
    changeActive5(e) {
      e.currentTarget.className = "lowtext51";
    },
    removeActive5(e) {
      e.currentTarget.className = "lowtext5";
    },
    changeActive6(e) {
      e.currentTarget.className = "lowtext61";
    },
    removeActive6(e) {
      e.currentTarget.className = "lowtext6";
    },

    moveLeft() {
      for (let i = 0; i < this.arrLow.length - 1; i++) {
        const tempBackground = this.arrLow[i].ax;
        this.arrLow[i].ax = this.arrLow[i + 1].ax;
        this.arrLow[i + 1].ax = tempBackground;
      }
    },

    moveRight() {
      for (let i = this.arrLow.length - 1; i > 0; i--) {
        const tempBackground = this.arrLow[i].ax;
        this.arrLow[i].ax = this.arrLow[i - 1].ax;
        this.arrLow[i - 1].ax = tempBackground;
      }
    },
  },
  mounted() {
    var effect = 0;

    new swiper(".swiper-container", {
      loop: true,
      autoplay: true,
      speed: 2500,
      slidesPerView: 4,
      spaceBetween: 0,
      centeredSlides: true,
      watchSlidesProgress: true,
      on: {
        setTranslate: function () {
          var slides = this.slides;
          for (var i = 0; i < slides.length; i++) {
            var slide = slides.eq(i);
            var progress = slides[i].progress;
            //slide.html(progress.toFixed(2)); 看清楚progress是怎么变化的
            slide.css({ opacity: "", background: "" });
            slide.transform(""); //清除样式
          }
        },
        setTransition: function (transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            slide.transition(transition);
          }
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

    var pe = document.getElementById("progressEffect");
  },
};
</script>

<style scoped>
.lowcontain {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
}

.lowpartone {
  width: 100%;
  height: 500px;
  background: url("../assets/images/lowpass/banner.png");
  background-size: 100% 100%;
}

.low_partone_font2 {
  width: 205px;
  height: 24px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #a0acbb;
  line-height: 20px;
  position: relative;
  top: 150px;
  left: 125px;
}

.low_partone_font1 {
  width: 347px;
  height: 44px;
  font-size: 36px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 44px;
  position: relative;
  top: 150px;
  left: 120px;
}

.lowparttwo {
  width: 100%;
  height: 500px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  position: relative;
}

.lowlogo {
  width: 48px;
  height: 8px;
  background: linear-gradient(-45deg, transparent 1px, #048dfe 20%);
  opacity: 1;
  margin: 50px 50%;
  position: relative;
}

.lowlogo2 {
  width: 70px;
  height: 35px;
  background: #0d1721;
  border-radius: 64px 64px 64px 64px;
  opacity: 1;
  position: relative;
  top: -20px;
  left: 730px;
}

.lowfont {
  width: 45px;
  height: 42px;
  font-size: 18px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  top: 5px;
}

.lowfont2 {
  width: 448px;
  height: 48px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #26252b;
  line-height: 48px;
  position: relative;
  top: -32px;
  left: 50px;
}

.lowtitle {
  width: 1200px;
  height: 65px;
  background: #f3f7fb;
  border-radius: 103px 103px 103px 103px;
  opacity: 1;
  top: 20px;
  left: 356px;
  position: relative;
}

.lowtext1 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
}

.lowtext11 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(227, 227, 227, 0.24);
  border-radius: 39px 39px 39px 39px;
  opacity: 1;
  position: relative;
  top: 0px;
  left: 0px;
}

.lowtext2 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: relative;
  top: -65px;
  left: 160px;
}

.lowtext21 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(227, 227, 227, 0.24);
  border-radius: 39px 39px 39px 39px;
  opacity: 1;
  position: relative;
  top: -65px;
  left: 160px;
}

.lowtext3 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: relative;
  top: -130px;
  left: 320px;
}

.lowtext31 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(227, 227, 227, 0.24);
  border-radius: 39px 39px 39px 39px;
  opacity: 1;
  position: relative;
  top: -130px;
  left: 320px;
}

.lowtext4 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: relative;
  top: -195px;
  left: 500px;
}

.lowtext41 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(227, 227, 227, 0.24);
  border-radius: 39px 39px 39px 39px;
  opacity: 1;
  position: relative;
  top: -195px;
  left: 500px;
}

.lowtext5 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: relative;
  top: -260px;
  left: 700px;
}

.lowtext51 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(227, 227, 227, 0.24);
  border-radius: 39px 39px 39px 39px;
  opacity: 1;
  position: relative;
  top: -260px;
  left: 700px;
}

.lowtext6 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  position: relative;
  top: -325px;
  left: 940px;
}

.lowtext61 {
  width: 200px;
  height: 65px;
  line-height: 65px;
  background: #ffffff;
  box-shadow: 0px 4px 12px 0px rgba(227, 227, 227, 0.24);
  border-radius: 39px 39px 39px 39px;
  opacity: 1;
  position: relative;
  top: -325px;
  left: 940px;
}

span {
  width: 27px;
  height: 27px;
  font-size: 18px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #a0acbb;
  line-height: 27px;
  position: relative;
}

.lowlogo3 {
  width: 48px;
  height: 8px;
  background: linear-gradient(-45deg, transparent 1px, #048dfe 20%);
  opacity: 1;
  margin: 90px 50%;
  position: relative;
}

.lowfont3 {
  width: 725px;
  height: 48px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #26252b;
  line-height: 48px;
  position: relative;
  top: 20px;
  left: -340px;
}

.lowleft {
  width: 24px;
  height: 24px;
  position: relative;
  background: url("../assets/images/lowpass/Group235.png");
  background-size: 100% 100%;
  top: 60px;
  left: 200px;
}

.lowright {
  width: 24px;
  height: 24px;
  position: relative;
  background: url("../assets/images/lowpass/Group922.png");
  background-size: 100% 100%;
  top: -820px;
  left: 1600px;
}

.lowpartthree {
  width: 100%;
  background: url(../assets/images/lowpass/background.png);
  background-size: 100% 100%;
  margin-top: 35px;
  padding-top: 30px;
  /* display: flex;
  justify-content: center; */
}

.lowdiv1 {
  width: 286px;
  height: 48px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin:auto;
}
.lowdiv2-3 {
  display: flex;
  width: 1300px;
  margin: auto;
}

.lowdiv2 {
  width: 650px;
  height: 360px;
  background: url("../assets/images/lowpass/Rectangle704.png");
  background-size: 100% 100%;
}

.lowdiv3 {
  width: 650px;
  height: 360px;
  background: #ffffff;
  display: flex;
  flex-flow: row wrap;
  padding: 15px 70px;
}

.kuan {
  width: 170px;
  height: 170px;
  background: url("../assets/images/lowpass/Group288.png");
  background-size: 100% 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.tu {
  width: 90px;
  height: 90px;
  margin: 0px auto;
}

.zi {
  width: 100%;
  height: 21px;
  font-size: 16px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #000000;
  line-height: 21px;
  margin-top: -45px;
}


.lowzi2 {
  width: 400px;
  height: 48px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  margin: 35px auto 5px;
}

.tulist {
  width: 100%;
  height: 200px;
  position: relative;
  top: -120px;
  left: -800px;
}

.tulist > div:hover {
  scale: 1.1;
  transition-duration: 0.8s;
  z-index: 666;
}

.cardstyle {
  top: 100px;
  left: 220px;
  width: 100%;
  height: 500px;
}

.imgstyle {
  width: 100%;
  height: 100%;
}

.stylediv {
  width: 200px;
  height: 100%;
}

.lowpartfour {
  width: 100%;
  height: 1100px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  font-size: 16px;
}

.lowlogo4 {
  width: 48px;
  height: 8px;
  background: linear-gradient(-45deg, transparent 1px, #048dfe 20%);
  opacity: 1;
  margin: 50px 50%;
  position: relative;
}

.lowfont4 {
  width: 448px;
  height: 48px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #26252b;
  line-height: 48px;
  position: relative;
  top: 20px;
  left: -200px;
}

.low4div1 {
  width: 423px;
  height: 425px;
  position: relative;
  top: 35px;
  left: 250px;
}

.low4img1 {
  width: 423px;
  height: 231px;
  background: url("../assets/images/lowpass/Rectangle760.png");
  background-size: 100% 100%;
}

.low4title1 {
  width: 124px;
  height: 28px;
  font-size: 20px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #0d1721;
  line-height: 28px;
  position: relative;
  top: 10px;
  left: 20px;
}

.low4context1 {
  width: 375px;
  height: 81px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #a0acbb;
  line-height: 20px;
  position: relative;
  text-align: left;
  top: 20px;
  left: 20px;
}

.low4div2 {
  width: 423px;
  height: 425px;
  position: relative;
  top: -390px;
  left: 750px;
}

.low4img2 {
  width: 423px;
  height: 231px;
  background: url("../assets/images/lowpass/Group1233.png");
  background-size: 100% 100%;
}

.low4title2 {
  width: 124px;
  height: 28px;
  font-size: 20px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #0d1721;
  line-height: 28px;
  position: relative;
  top: 10px;
  left: 10px;
}

.low4context2 {
  width: 375px;
  height: 81px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #a0acbb;
  line-height: 20px;
  position: relative;
  text-align: left;
  top: 20px;
  left: 20px;
}

.low4div3 {
  width: 423px;
  height: 425px;
  position: relative;
  top: -810px;
  left: 1250px;
}

.low4img3 {
  width: 423px;
  height: 231px;
  background: url("../assets/images/lowpass/Group1234.png");
  background-size: 100% 100%;
}

.low4title3 {
  width: 124px;
  height: 28px;
  font-size: 20px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #0d1721;
  line-height: 28px;
  position: relative;
  top: 10px;
  left: 10px;
}

.low4context3 {
  width: 375px;
  height: 43px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #a0acbb;
  line-height: 20px;
  position: relative;
  text-align: left;
  top: 20px;
  left: 10px;
}

.lowlogo5 {
  width: 48px;
  height: 8px;
  background: linear-gradient(-45deg, transparent 1px, #048dfe 20%);
  opacity: 1;
  position: relative;
  top: -800px;
  left: 950px;
}

.lowremark {
  width: 70px;
  height: 35px;
  background: #0d1721;
  border-radius: 64px 64px 64px 64px;
  opacity: 1;
  position: relative;
  top: 20px;
  left: -200px;
}

.low5font2 {
  width: 45px;
  height: 42px;
  font-size: 18px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  top: 5px;
}

.low5context {
  width: 442px;
  height: 42px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0d1721;
  line-height: 48px;
  position: relative;
  top: -25px;
  left: -150px;
}

.low5back2 {
  width: 365px;
  height: 403px;
  background: #ffffff;
  /* box-shadow: 0px 4px 12px 0px rgba(178, 183, 187, 0.24);
  border-radius: 4px 4px 4px 4px; */
  opacity: 1;
  position: relative;
  top: -1200px;
  left: 1250px;
}

li {
  list-style-type: none;
  text-align: left;
  position: relative;
  top: 0px;
  margin: 25px 0;
}

li img {
  position: relative;
  top: 10px;
  left: 10px;
}

.low5back2 li span {
  width: 194px;
  height: 30px;
  font-size: 18px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0d1721;
  line-height: 30px;
  position: relative;
  top: 2px;
  left: 20px;
}

.low5backimg {
  width: 784px;
  height: 450px;
  background: url("../assets/images/lowpass/Rectangle49.png");
  background-size: 100% 100%;
  position: relative;
  top: -740px;
  left: 400px;
}

.lowpartfive {
  width: 100%;
  height: 470px;
  background: #0e1c29;
  border-radius: 0px 0px 0px 0px;
}

.low5frame1 {
  width: 424px;
  height: 406px;
  background: url("../assets/images/lowpass/Frame403.png");
  background-size: 100% 100%;
  position: relative;
  top: 30px;
  left: 180px;
}

.low5frame2 {
  width: 424px;
  height: 406px;
  background: url("../assets/images/lowpass/Frame447.png");
  background-size: 100% 100%;
  position: relative;
  top: -375px;
  left: 680px;
}

.low5frame3 {
  width: 424px;
  height: 406px;
  background: url("../assets/images/lowpass/Frame448.png");
  background-size: 100% 100%;
  position: relative;
  top: -780px;
  left: 1180px;
}

.lowpartsix {
  width: 100%;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
}

.low6img {
  width: 100%;
  height: 469px;
  background: url("../assets/images/lowpass/Group918.png");
  background-size: 100% 100%;
  margin-top: 100px;
}

.lowlogo6 {
  width: 48px;
  height: 8px;
  background: linear-gradient(-45deg, transparent 1px, #048dfe 20%);
  opacity: 1;
  position: relative;
  left: 950px;
  top: 30px;
}

.lowfont6 {
  width: 388px;
  height: 48px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0d1721;
  line-height: 48px;
  position: relative;
  top: 20px;
  left: -150px;
}

.low6div {
  display: flex;
  height: 160px;
  align-items: center;
  justify-content: space-around;
  padding: 0px 200px;
}

.low6div1 {
  width: 200px;
  height: 64px;
  line-height: 64px;
  font-size: 24px;
  background: #f3f7fb;
  border-radius: 5px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.low6img1 {
  width: 26px;
  height: 26px;
}


.swiper-wrapper /deep/ .swiper-slide {
  width: 400px;
  height: 403px;
  margin-top: 0px;
  float: left;
}

.low_42_1 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: 0px;
	left: -50px; */
  transition: width 2s;
  object-fit: fill;
  float: left;
}

.low_42_2 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -403px;
	left: 120px; */
  object-fit: fill;
  float: left;
}

.low_42_3 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -806px;
	left: 290px; */
  object-fit: fill;
}

.low_42_4 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -1209px;
	left: 460px; */
  object-fit: fill;
}

.low_42_5 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -1612px;
	left: 630px; */
  object-fit: fill;
}

.low_42_6 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -2015px;
	left: 800px; */
  object-fit: fill;
}

.low_42_7 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -2418px;
	left: 970px; */
  object-fit: fill;
}

.low_42_8 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -2821px;
	left: 1140px; */
  object-fit: fill;
}

.low_42_9 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -3224px;
	left: 1310px; */
  object-fit: fill;
}

.low_42_10 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -3627px;
	left: 1480px; */
  object-fit: fill;
}

.low_42_11 {
  width: 400px;
  height: 403px;
  /* position: relative;
	top: -4030px;
	left: 1650px; */
  object-fit: fill;
}

.low_42_1 img {
  width: 400px;
  height: 403px;
}

.low_42_2 img {
  width: 400px;
  height: 403px;
}

.low_42_3 img {
  width: 400px;
  height: 403px;
}

.low_42_4 img {
  width: 400px;
  height: 403px;
}

.low_42_5 img {
  width: 400px;
  height: 403px;
}

.low_42_6 img {
  width: 400px;
  height: 403px;
}

.low_42_7 img {
  width: 400px;
  height: 403px;
}

.low_42_8 img {
  width: 400px;
  height: 403px;
}

.low_42_9 img {
  width: 400px;
  height: 403px;
}

.low_42_10 img {
  width: 400px;
  height: 403px;
}

.low_42_11 img {
  width: 400px;
  height: 403px;
}

.lowtitle2 {
  width: 100%;
  height: 144px;
  background: #f3f7fb;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  display: flex;
  justify-content: space-around;
  padding: 0px 200px;
  margin-bottom: 30;
}

.img_list {
  width: 200px;
  height: 144px;
  background-color: #15568b;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}


.low_42_1_div {
  width: 71px;
  height: 25px;
  position: relative;
  top: -50px;
  left: 10px;
}

.low_42_1_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_1_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -40px;
}

.low_42_2_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_2_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -40px;
}

.low_42_3_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_3_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -40px;
}

.low_42_4_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_4_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -30px;
}

.low_42_5_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_5_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -40px;
}

.low_42_6_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_6_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -30px;
}

.low_42_7_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_7_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -40px;
}

.low_42_8_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_8_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -25px;
}

.low_42_9_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 18px;
}

.low_42_9_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -25px;
}

.low_42_10_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 20px;
}

.low_42_10_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -25px;
}

.low_42_11_logo {
  width: 8px;
  height: 15px;
  background: #ffffff;
  position: relative;
  top: 20px;
}

.low_42_11_font {
  width: 163px;
  height: 22px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
  position: relative;
  left: -5px;
}

.container {
  display: flex;
}

.item {
  width: 100px;
  height: 100px;
  margin-right: 10px;
}

.arrow {
  width: 50px;
  height: 50px;
  background-color: gray;
  cursor: pointer;
}
</style>
