<template>
  <div class="momcontain">
    <div class="mompartone">
      <div class="momtitle">
        <div class="momfont1">LINX MOM</div>
        <div class="momfont2">软件系统及功能模板</div>
      </div>
      <div class="momfont3">LINX MOM software system and function template</div>
    </div>

    <div class="momlogo"></div>
    <div class="momdes">LINX MOM 配置化低代码应用实施产品模块</div>

    <div class="momparttwo">
      <div
        :class="x.lineShow ? 'mompart-item active' : 'mompart-item'"
        @click="changeNav(i)"
        v-for="(x, i) in momArr"
        :key="i"
      >
        <img :src="x.image" />
        <div class="mompart-name">{{ x.fontName }}</div>
        <div class="mompart-name">{{ x.textName }}</div>
      </div>
    </div>

    <!-- 图文描述-->
    <div class="mombody">
      <div v-for="(x, i) in bodyArr" :key="i">
        <div :class="x.className" v-show="x.isShow"></div>
        <div class="mombody_01_div" v-show="x.isShow">
          <div class="mombody_01_img1" v-show="x.isShow"></div>
          <div class="mombody_01_font1" v-show="x.isShow">
            {{ x.fontName1 }}
            <span class="mombody_01_span" v-show="x.isShow">{{ x.fontName2 }}</span>
          </div>

          <div class="mombody_01_img2" v-show="x.isShow"></div>
          <div class="mombody_01_font2" v-show="x.isShow">{{ x.fontName3 }}</div>

          <div class="mombody_01_img3" v-show="x.isShow && x.fontName4"></div>
          <div class="mombody_01_font3" v-show="x.isShow">{{ x.fontName4 }}</div>
        </div>
      </div>
    </div>

    <div class="div_title">
      <div class="momlogo1"></div>
      <div class="mom3title">行业解决方案</div>
    </div>

    <div class="carousel_div">
      <el-carousel indicator-position="outside">
        <el-carousel-item>
          <div class="mompartthree">
            <div class="mom3div1">
              <div class="mom3div1img1"></div>
              <div class="mom3div1font1">PCBA行业APS解决方案</div>
              <div class="mom3div1font2">涉及产品案例</div>
              <div class="mom3div1font3"></div>
              <div class="mom3div1font4">液晶显示器</div>
              <div class="mom3div1font5">机顶盒</div>
              <div class="mom3div1font6">机械硬盘</div>

              <div class="mom_router">路由器</div>
              <div class="mom_car_bcm">汽车BCM</div>
              <div class="mom_car_control">汽车空调控制</div>
            </div>

            <div class="mom_div_two">
              <div class="mom_div_two_img"></div>
              <div class="mom_div_two_font">汽车零部件行业MOM解决方案</div>
              <div class="mom_div_two_font_two">涉及产品案例</div>
              <div class="mom_div_two_font_three"></div>
              <div class="mom_div_two_font_four">汽车座椅</div>
              <div class="mom_div_two_font_five">变速箱壳体</div>
              <div class="mom_div_two_font_six">汽车BCM</div>

              <div class="mom_router">离合器壳体</div>
              <div class="mom_car_bcm">变速箱凸轮轴</div>
              <div class="mom_car_control">汽车空调控制</div>
            </div>

            <div class="mom_div_three">
              <div class="mom_div_three_img"></div>
              <div class="mom_div_three_font">
                端到端 End to End 条码及防伪防窜货解决方案
              </div>
              <div class="mom_div_two_font_two">涉及产品案例</div>
              <div class="mom_div_two_font_three"></div>
              <div class="mom_div_three_font_four">云端防伪管理</div>
              <div class="mom_div_three_font_five">防窜货管理</div>
              <div class="mom_div_three_font_six">拖拽式标签设计</div>

              <div class="mom_title_desc">标签设计支持图片、文字、流水号</div>
              <div class="mom_support">支持100+条码嵌入</div>
            </div>
          </div>
        </el-carousel-item>

        <el-carousel-item>
          <div class="mom_programme" v-show="true">
            <!-- <div class="momlogo1"></div>
						<div class="mom3title">行业解决方案</div> -->
            <div class="mom_context_one">
              <div class="mom_context_img"></div>
              <div class="mom_context_font_one">焊接(多行业)解决方案案</div>
              <div class="mom_context_font_two">涉及产品案例</div>
              <div class="mom_context_font_three"></div>
              <div class="mom_context_font_four">汽车座椅</div>
              <div class="mom_context_font_five">高铁</div>

              <div class="mom_context_font_six">风力发电</div>
            </div>

            <div class="mom_21_2">
              <div class="mom_21_img"></div>
              <div class="mom_21_font_1">多行业条码标签设计布局与打印</div>
              <div class="mom_21_font_2">涉及产品案例</div>
              <div class="mom_21_font_3"></div>
              <div class="mom_21_font_4">汽车机械零部件</div>
              <div class="mom_21_font_5">工业电气</div>
              <div class="mom_21_font_6">服装</div>
              <div class="mom_21_font_7">汽车电子</div>
              <div class="mom_21_font_8">消费电子</div>
              <div class="mom_21_font_9">PCBA</div>
            </div>

            <!-- <div class="mom_21_3">
              <div class="mom_21_3_img"></div>

              <div class="mom_21_3_font">更多行业及应用</div>
              <div class="mom_21_button_3">
                <div class="mom_21_button_3_font">请联系我们</div>
                <div class="mom_21_button_3_img"></div>
              </div>
            </div> -->
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- <button class="mom_buttone_one" @click="changeTab(0)" v-show="this.showButton"></button>
		<button class="mom_buttone_one_1" @click="changeTab(0)" v-show="this.showButton1"></button>
		<button class="mom_buttone_two" @click="changeTab(1)" v-show="this.showButton"></button>
		<button class="mom_buttone_two_2" @click="changeTab(1)" v-show="this.showButton1"></button> -->

    <div class="mom_four">
      <div class="mom_40"></div>
      <div class="mom_41">工艺覆盖类型</div>

      <!-- <div class="mom_42">
				<div :class="item.className" v-for="(item, index) in lowimglist" :key="index">
					<img :src="item.url" @mouseover="showImageUrl(index)" @mouseleave="hideImageUrl(index)">
					<div class="mom_42_1_div">
						<div :class="`${item.className}_logo`"></div>
						<div :class="`${item.className}_font`">{{ item.text }}</div>
					</div>
				</div>
			</div> -->

      <div
        class="swiper-container swiper-container-initialized swiper-container-horizontal"
        style="height: 400px; width: 100%; margin-top: 35px"
      >
        <div
          class="swiper-wrapper"
          style="
            transition-duration: 0ms;
            transform: translate3d(-951.429px, 0px, 0px);
            width: 100%;
            height: 400px;
          "
        >
          <div
            class="swiper-slide"
            :data-swiper-slide-index="`${index}`"
            style="transition-duration: 0ms"
            v-for="(item, index) in lowimglist"
            :key="index"
          >
            <div :class="item.className">
              <img :src="item.urlImg" />

              <div class="mom_42_1_div">
                <div :class="`${item.className}_logo`"></div>
                <div :class="`${item.className}_font`">{{ item.text }}</div>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-button-prev" tabindex="0" role="button" aria-label="Previous slide"></div>
					<div class="swiper-button-next" tabindex="0" role="button" aria-label="Next slide"></div> -->
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import { reactive, ref } from "vue";

// @ts-ignore
import Footer from "./foot.vue";
// import url1 from '../assets/images/lowpass/list/Group257.png'
// import url2 from '../assets/images/lowpass/list/Group257@2x.png'

// @ts-ignore
import swiper from "swiper";

export default {
  components: { Footer },

  data() {
    return {
      number: 0,
      showButton: true,
      showButton1: false,
      props: {},

      lowimglist: [
        {
          className: "mom_42_1",
          text: "焊接工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group257.png"),
          // url: url1,
          // @ts-ignore
          // urlImg: url2
          urlImg: require("../assets/images/lowpass/list/Group257@2x.png"),
        },
        {
          className: "mom_42_2",
          text: "SMT工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group258.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group258@2x.png"),
        },
        {
          className: "mom_42_3",
          text: "注塑工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group259.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group259@2x.png"),
        },
        {
          className: "mom_42_4",
          text: "机加工工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group260.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group260@2x.png"),
        },
        {
          className: "mom_42_5",
          text: "冲压工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group261.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group261@2x.png"),
        },
        {
          className: "mom_42_6",
          text: "半导体工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group262.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group262@2x.png"),
        },
        {
          className: "mom_42_7",
          text: "电镀工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group263.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group263@2x.png"),
        },
        {
          className: "mom_42_8",
          text: "电子装配工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group264.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group264@2x.png"),
        },
        {
          className: "mom_42_9",
          text: "表面处理工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group265.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group265@2x.png"),
        },
        {
          className: "mom_42_10",
          text: "机电装配工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group266.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group266@2x.png"),
        },
        {
          className: "mom_42_11",
          text: "钣金、折弯冲压工艺",
          // @ts-ignore
          url: require("../assets/images/lowpass/list/Group267.png"),
          // @ts-ignore
          urlImg: require("../assets/images/lowpass/list/Group267@2x.png"),
        },
      ],
      momArr: [
        {
          className: "momdiv1",
          fontName: "APS",
          lineShow: true,
          image: require("../assets/images/mom/Component13.png"),
          textName: "高级计划排程系统",
        },
        {
          className: "momdiv2",
          lineShow: false,
          image: require("../assets/images/mom/Group1252.png"),
          fontName: "MES",
          textName: "制造执行系统",
        },
        {
          className: "momdiv3",
          lineShow: false,
          image: require("../assets/images/mom/Group1253.png"),
          fontName: "WMS",
          textName: "仓储管理系统",
        },
        {
          className: "momdiv4",
          lineShow: false,
          image: require("../assets/images/mom/Component14.png"),
          fontName: "EAM",
          textName: "设备管理系统",
        },
        {
          className: "momdiv5",
          lineShow: false,
          image: require("../assets/images/mom/Component14.png"),
          fontName: "QMS",
          textName: "质量管理系统",
        },
      ],

      bodyArr: [
        {
          className: "mombody_01",
          isShow: true,
          fontName1: "APS是生产排程系统,",
          fontName2:
            "（Advanced Planning and Scheduling）的缩写，是企业实施精益制造的有效工具，实现生产计划及物料计划的规范化和程序化。根据设置的交付条件，结合物料库存管理，并综合考虑设备/人员、前后置时间、工作日历等约束条件，通过排程计算，进行生产排程以及物料需求。并对排程的过程进行实时监控，对排程的结果进行分析，给出排程质量分析，并给出的建议加班时间等。",
          fontName3:
            "LINX-APS 通过优化生产计划，提升生产效率，实现精益采购，降低设备及人力成本，确保订单准时交货；通过管理透明化，明确计划与执行的差异性，借助流程不断改进、管理不断规范和差异逐渐消除。",
          fontName4: "通过与MES、WMS、ERP系统的集成，实现全供应链的生产管理水平提升。",
        },
        {
          className: "mombody_02",
          isShow: false,
          fontName1: "MES是制造执行管理系统",
          fontName2:
            "（Manufacturing Executive System）的缩写，是对这个制造过程的全流程管理系统，LINX-MES 为一体化设计平台产品，以BOM 及工艺Routine为基础，核心实现工艺流程的管控、原材料及配方的管控及正反向追溯、 以工单为生产运行主线条，与PLC系统/数控集成实时通讯实线制造过程中工单在工艺、人员、设备严密管控状态下的执行。",
          fontName3:
            "系统与主流ERP 、PLM、Cax可实现集成实时通讯，让整个工厂在完整的信息系统管理指挥下有序运行。",
          fontName4:
            "作为平台核心，具备多功能模块可选择配置，与灵娃其他十几个在物流、生产、设备、质量、人员等不同模块一体化设计，整体单独或部分集成运用， 形成不同的模式稳定可靠地高效运行。 过程实时数据可通过定制化界面展现。",
        },
        {
          className: "mombody_03",
          isShow: false,
          fontName1: "WMS是仓库管理系统",
          fontName2:
            "（Warehouse Management System）的缩写，是对仓库作业：包括仓库入库、仓库出库、库存存储、 库存拣货、库存调拨、入库质检、质量管控、库存盘点等的管理，更细致更全面的有效控制并跟踪仓库业务的全流程， 完善仓库信息化管理，实现仓库管理的现代化。",
          fontName3:
            " LINX-WMS 通过全面采用条码/RFID，读取终端，优化现有作业流程，建立标准化作业流程，实现原材料库、JIT库、线边库、 在制品库、成品库各类库独立和集中管理，提高效率，减少差错率，实现仓库管理水平的提升、降低成本。",
          fontName4:
            "通过与MES、APS、ERP、WCS 等系统的集成，实现全供应链的物流管理水平提升。",
        },
        {
          className: "mombody_05",
          isShow: false,
          fontName1:
            "覆盖设备、工装、夹具、检具、模具的一体化管理， 涵盖资产管理、点检、维修、预防性保养、备品备件管理。",
          fontName2: "",
          fontName3:
            "覆盖设备全生命周期的管理包括事后维修、定期校验、预防性维护、 生产维护，根据维护保养计划电子化派工， 提升维护保养的及时性、 达成率，降低设备发生故障的机率，提高企业利润、降低成本。",
          fontName4: "",
        },

        {
          className: "mombody_04",
          isShow: false,
          fontName1: "QMS质量管理系统，",
          fontName2:
            "以来料检验及生产制造为主流程，覆盖进货检验、生产过程检验（首检、巡检、工序完工检、终检）， 销售出库检验，与检验工具、测试设备和自动线集成， 实现质量数据数字化管理。",
          fontName3:
            " 对于来料检验，系统实现全自动检测任务计划排程及人员调度，自动检测设备数据采集及汇聚，与ERP实现检验计划及监测数据的交互。",
          fontName4:
            " 可通过与MES、ERP系统的集成，实现质量标准、检测方法、关键工序、工艺控制要求、定期审核、统计控制质量管理要求的水平提升。",
        },
      ],
    };
  },
  methods: {
    changeTab(index) {
      this.number = index;
      if (index == 0) {
        this.showButton = true;
        this.showButton1 = false;
      } else {
        this.showButton = false;
        this.showButton1 = true;
      }
    },
    changeNav(index) {
      this.momArr.forEach((x, i) => {
        x.lineShow = index == i ? true : false;
      });
      this.bodyArr.forEach((x, i) => {
        x.isShow = index == i ? true : false;
      });
    },

    showImageUrl(index) {
      this.lowimglist[index].url = this.lowimglist[index].urlImg;
      this.lowimglist[index].url.style = "width:300px;height:400px";
      // 		scale: 1.5;
      // transition-duration: 0.8s;
      // z-index: 666;
    },

    hideImageUrl(index) {
      this.lowimglist[index].url = this.lowimglist[index].url.replace("@2x", "");
    },
  },
  mounted() {
    // 	new swiper(".swiper-container",
    // 	{loop: true,
    // 		// 循环模式选项// 如果需要分页器
    // 		pagination: {el: ".swiper-pagination",},
    // 		// 如果需要前进后退按钮
    // 		navigation: {nextEl: ".swiper-button-next",prevEl: ".swiper-button-prev",}});
    //
    var effect = 0;

    new swiper(".swiper-container", {
      loop: true,
      autoplay: true,
      speed: 2500,
      slidesPerView: 4,
      spaceBetween: 0,
      centeredSlides: true,
      watchSlidesProgress: true,
      on: {
        setTranslate: function () {
          var slides = this.slides;
          for (var i = 0; i < slides.length; i++) {
            var slide = slides.eq(i);
            var progress = slides[i].progress;
            //slide.html(progress.toFixed(2)); 看清楚progress是怎么变化的
            slide.css({ opacity: "", background: "" });
            slide.transform(""); //清除样式

            // if(effect == 1){
            // slide.transform('scale('+(1 - Math.abs(progress)/8)+')');
            // }else if(effect == 2){
            // 	slide.css('opacity',(1-Math.abs(progress)/6));
            // slide.transform('translate3d(0,'+ Math.abs(progress)*20+'px, 0)');
            // }
            // else if(effect == 3){
            // slide.transform('rotate('+ progress*30+'deg)');
            // }else if(effect == 4){
            // 	slide.css('background','rgba('+ (255 - Math.abs(progress)*20) +','+ (127 + progress*32) +',' + Math.abs(progress)*64 + ')');
            // }
          }
        },
        setTransition: function (transition) {
          for (var i = 0; i < this.slides.length; i++) {
            var slide = this.slides.eq(i);
            slide.transition(transition);
          }
        },
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });

    var pe = document.getElementById("progressEffect");
    // // @ts-ignore
    // pe.onchange = function(){
    // // @ts-ignore
    // effect = this.value
    // 	// @ts-ignore
    // 	swiper.update();
    // }
  },
};
</script>
<style scoped>
@import "../css/lowMom.css";

.mom_carousel {
  width: 100%;
  height: 800px;

  /deep/ .mom_carousel {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.el-carousel {
  width: 100%;
  height: 500px;
  position: relative;
  top: -10px;
}

.el-carousel /deep/ .el-carousel__container {
  width: 100%;
  height: 450px;
  position: relative;
  top: 0px;
}

.el-carousel /deep/ .el-carousel__button {
  border: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.2);
}

.el-carousel /deep/ .is-active .el-carousel__button {
  background: #3f8ec8;
  height: 10px;
}

.el-carousel /deep/ --horizontal {
  display: none;
}

.carousel_div {
  width: 100%;
  height: 500px;
  position: relative;
  /* margin-top: 50px; */
}

.el-carousel /deep/ el-carousel__item is-active is-animating mom_carousel_item {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  overflow: hidden;
  z-index: calc(var(--el-index-normal) - 1);
}
</style>
