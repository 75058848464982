<template>
	<div id="boxCenter">
		<nav>
			<el-row :gutter="20">
				<el-col :span="10">
					<img src="./assets/images/LinxLogo.png" style="margin-left: -60px;position: absolute; margin-top: 10px;">
				</el-col>
				<el-col :span="2" v-for="(item,index) in navLit" :key="index" @click="handTab(index)">
					<router-link :to="item.link" :class="{ classred:index==current}">{{item.name}}</router-link>
				</el-col>
			</el-row>
		</nav>
		<!--跳转论坛-->
		<div class="go_furn" style="width: 50%; height: 3.5rem; margin-top: -40px; margin-left: 1050px;">
			<a class="app_a" href="http://smartflow.diskstation.me:8087" target="_blank" style="font-weight: bold; color: burlywood;">开发者社区</a>
		</div>
	</div>
	<router-view></router-view>
</template>
<script setup>
	import {
		ref
	} from 'vue'

	import devPixelRatio from "@/js/devicePixelRatio.js";

	const navLit = ref([{
			link: '/',
			name: '首页'
		},
		{
			link: '/lowPass',
			name: '低代码PaaS平台'
		},
		{
			link: '/lowMom',
			name: 'MOM软件系统产品'
		},
		{
			link: '/industry',
			name: '数字化精益工具'
		},
		{
			link: '/lowNumber',
			name: '工业电气行业数字化工厂解决方案'
		},
		{
			link: '/about',
			name: '关于我们'
		},
	])
	const current = ref(0)
	const handTab = (index) => {
		current.value = index;
	}



</script>

<style lang="less">
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
	}


	#boxCenter nav {
		width: 80%;
		height: 3.5rem;
		margin-left: -120px;
		line-height: 3.5rem;
		background-color: white;

		.el-col-2 {
			flex: initial;
			max-width: initial
		}
	}

	#boxCenter nav a {
		color: #A0ACBB;
		padding: 1.1rem 0;
	}

	#boxCenter nav .classred {
		width: 28px;
		height: 16px;
		font-size: 16px;
		font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
		font-weight: bold;
		color: #15568B;
		line-height: 16px;

		position: relative;

		&::after {
			content: '';
			position: absolute;
			bottom: .3rem;
			left: 0;
			width: 100%;
            height: 4px;
			border-radius: 0px 0px 0px 0px;
			background: #15568B;
		}
	}


	// @media screen and (min-width:1301px) and (max-width:1600px) {
	// 	body {
	// 		background-color: blue;
	// 		font-size: .8rem;
	// 	}
	// }

	// @media screen and (min-width:1000px) and (max-width:1300px) {
	// 	body {
	// 		background-color: yellow;
	// 		font-size: .7rem;
	// 	}
	// }

	// @media screen and (max-width:500px) {
	// 	body {
	// 		background-color: green;
	// 		font-size: .7rem
	// 	}

	// 	#boxCenter .el-row .el-col-10 {
	// 		display: none;
	// 	}

	// 	#boxCenter .el-row .el-col-2:nth-child(2),
	// 	#boxCenter .el-row .el-col-2:nth-child(6) {
	// 		padding-left: 20px !important;
	// 	}

	// 	#boxCenter nav {
	// 		overflow: auto;
	// 	}
	// }

	.app_a {
		color: #1053d8;
		text-decoration: none;
		border-bottom: 1px solid transparent;
	}

	.app_a:hover{
		text-decoration: underline;
	}
</style>