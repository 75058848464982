<template>
  <div class="datacontain">
    <div class="frame949"></div>

    <div class="datapart2">
      <div class="dplogo"></div>
      <div class="dp_title">
        <div class="dpstayle"><span class="spfont">8款</span></div>
        <div class="dpfont">制造精益现场管理工具</div>
      </div>

      <!-- 左侧竖线 -->
      <div class="dp_01"></div>

      <!-- 单项竖线和按钮盒子 -->
      <div v-for="(x, i) in linesArr" :key="i" :class="x.className">
        <div :class="`${x.className}_1`" style="border: 1px solid #048dfe" v-show="x.isShow"></div>
        <div :class="`${x.className}_2`" @click="checked(x, i)" v-show="x.isShow" style="background-color: #048dfe;">
        </div>
        <div :class="`${x.className}_2`" @click="checked(x, i)" v-show="!x.isShow" style="background-color: #fff;"></div>

        <div :class="`${x.className}_3`" @click="checked(x, i)" v-show="x.isShow">
          <div>{{ x.discrible }}</div>
          <div>{{ x.label }}</div>
        </div>
        <div :class="`${x.className}_4`" @click="checked(x, i)" v-show="!x.isShow">
          <div>{{ x.discrible }}</div>
          <div>{{ x.label }}</div>
        </div>



      </div>

      <!-- 右侧图片 -->
      <div class="dp_back_01" v-for="(x, i) in imgsArr" :key="i">

        <div :class="`${x.className}_img`" v-show="x.isShow"></div>
        <div :class="`${x.className}_font`" v-show="x.isShow">
          <div :class="`${x.className}_zi`">{{ x.discrible }}</div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
// @ts-ignore
import Footer from "./foot.vue";

export default {
  components: { Footer },
  data() {
    return {
      linesArr: [
        { className: "dp_02", isShow: true, discrible: "01 ANDON", label: "现场快反管理工具" },
        { className: "dp_03", isShow: false, discrible: "02 E-Suggestion", label: "员工合理化建议管理工具" },
        { className: "dp_04", isShow: false, discrible: "03 CPC", label: "变更点管理工具" },
        { className: "dp_05", isShow: false, discrible: "04 Training", label: "员工培训管理工具" },
        { className: "dp_06", isShow: false, discrible: "05 E-SOP", label: "电子操作指导书工具" },
        { className: "dp_07", isShow: false, discrible: "06 Layeraudit", label: "分层审核" },
        { className: "dp_08", isShow: false, discrible: "07 SPC", label: "统计过程质量控制工具" },
        { className: "dp_09", isShow: false, discrible: "08 E-Call", label: "电子物料拉动工具" },
      ],
      imgsArr: [
        { className: "dp_back_01", isShow: true, discrible: "作为一种整合现场信息系统的生产现场控制快反系统, 通过 '人'， '机'， '料'， '法'， '环' 系统性的将生产异常事件信息化，处理流程标准化， 帮助问题快速有效解决和提高生产效率的同时，对数据进行合理的统计分析，解决方案知识的有效积累，保持对工厂产线进行持续的改善。\n\n系统目的：可视化、公开化、透明化，形象直观地暴露问题，迅速快捷地传递信息，促进企业快反体系形成和建立。\n系统功能：计划、物料、设备、品质、产品、水电等异常事件报警类型定义；语音或闪烁报警、告警页面、邮件或手机推送等多种报警方式和逐级上报跟踪处理； 异常处理信息记录，经验积累池；报警数据点实施监视；任意区间内某项指标超过报警限值运行等异常事件累计查询。" },
        {
          className: "dp_back_02",
          isShow: false,
          discrible:
            "灵娃合理化建议管理工具，定位于制造企业自下而上的现场持续改善，以软件的方式帮助制造现场实现合理化建议的收集、跟踪、管理与总结。并对提出建议人员的建议给与反馈记录，形成正向闭环。可以根据位置、设备、问题类别进行的历史查询和汇总，有效助力企业形成持续推进持续改善的环境氛围。",
        },
        { className: "dp_back_03", isShow: false, discrible: "CPC 变更点管理工具\n核心工具\n稳定不变的生产是效率最大的，但在实际运营中，变更是常态。变更不可怕，可怕的是无计划的变更，无协同的变更。\n灵娃CPC工具，有效协同产品变更、物料变化、设备变化 、 人员变动、工艺变化 、等，让变更处于可控状态，以数字化的手段最大化的控制变更带来的质量和效率损失。\n全面实现现场变更有效管理。支持手机端，变更计划，提前通知变更消息，让变更变得简单可控。" },
        { className: "dp_back_04", isShow: false, discrible: "Training 员工培训管理工具\n生产管理系统工具\n灵娃Training员工培训管理工具系统，用于企业操作工培训管理，覆盖培训资料、培训流程（计划、申请、审批、派工），培训实施、培训报表，分级培训、 多技能工培训、返岗培训等全流程，系统以软件形式实现了通用汽车、福特汽车、日产汽车等大型车企对于培训管理的流程和管控要求。 通过与MES系统的集成，可以实现员工持证上岗控制。" },
        { className: "dp_back_05", isShow: false, discrible: "E-SOP 电子操作指导书工具\n生产管理系统工具\n灵娃ESOP电子操作指导书工具，用于生产现场的各类生产、工艺、质量、设备、人员等文档的电子化管理及现场实施显示工具， 系统涵盖工作流审批，可结合变更点管理，将各类文档、视频电子化系统性管理，并可结合MES实现根据生产产品对应的文件与产品、产线、工位进行绑定，确保每一次生产操作文档的正确显示。通过与PLM或CAX系统的接口，可以实现针对所制造产品的图纸实时调取和展示。 结合灵娃文档管理工具，实现各种受控文件的统一管理、集中控制。" },
        { className: "dp_back_06", isShow: false, discrible: "Layor Audit 分层审核管理工具\n生产管理系统工具\n灵娃Layer Audit分层审核工具是企业实现自上而下进行管理软件，支持多层级现场审核计划管理，支持临时现场审核管理，对于审核的问题点以目视化、系统化的方式跟踪、提醒，管理。 支持手机端，针对个人的审核计划提醒， 有效跟踪审核发现问题的整改计划进度。" },
        { className: "dp_back_07", isShow: false, discrible: "SPC 统计过程质量控制工具\n核心系统\nSPC是对通过对制造过程数据、质量检测数据进行统计过程分析实现质量控制的利器，是品质改善的行业标准方法。\n灵娃SPC工具，可单独使用，也可以结合MES/MOM/QMS的数据采集系统，实现生产线在线数据、质检实验室离线数据的联动， 对于被监控数据的趋势以及分布加以监测， 防患于未然，避免质量问题扩大化，控制质量风险，降低质量成本。" },
        { className: "dp_back_08", isShow: false, discrible: "E-CALL拉动式供料系统，是企业实施精益生产的物料配送方式，实现在需要时，按需要量，配送物料至相应产线站位的控制系统，能使物流和信息流有机的结合，避免人力、物料、空间的浪费。系统通过采用工单齐套性备料、现场物料消耗性备料相结合的方式，通过仓库向产线按时、按需配送物料，既保证产线不会因为缺料断线，也不会因为物料配送过多，现场溢料。系统通过看板也可以实时显示物料需求情况，以及物料紧急程度。 通过AGV调度系统的集成，还可以实现物料配送的及时化、无人化。" },

      ],
    };
  },
  created: function () {
    false;
    this.$emit("footer", true);
  },
  methods: {
    checked(item, index) {
      this.linesArr.forEach((x, i) => {
        x.isShow = index == i ? true : false;
      });
      this.imgsArr.forEach((x, i) => {
        x.isShow = index == i ? true : false;
      });
    },
  },
  computed: {},
};
</script>

<style>
.datacontain {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  position: absolute;
}

.frame949 {
  width: 100%;
  height: 500px;
  background: url("../assets/images/code/Frame949.png");
  background-size: 100% 100%;
}

.datapart2 {
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
}

.dplogo {
  width: 48px;
  height: 8px;
  background: linear-gradient(-45deg, transparent 1px, #048DFE 20%);
  opacity: 1;
  position: absolute;
  margin: 50px 50%;
}

.dpstayle {
  width: 40px;
  height: 25px;
  background: #0d1721;
  border-radius: 64px 64px 64px 64px;
  opacity: 1;
  position: absolute;
  margin-top: 88px;
  margin-left: 720px;
}

.spfont {
  width: 45px;
  height: 42px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Bold, HarmonyOS Sans SC;
  font-weight: bold;
  color: #ffffff;
  line-height: 24px;
}

.dpfont {
  width: 280px;
  height: 48px;
  font-size: 24px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0d1721;
  line-height: 48px;
  position: absolute;
  margin-top: 75px;
  margin-left: 750px;
}

.dp_title {
  position: relative;
  top: 0;
  left: 100px;
}

.dp_01 {
  width: 0px;
  height: 600px;
  opacity: 1;
  border: 1px solid rgba(13, 23, 33, 0.2);
  position: relative;
  top: 150px;
  left: 280px;
}

.dp_02 {
  width: 200px;
  height: 48px;
  position: absolute;
  left: 280px;
  margin-top: -450px;
}

.dp_02_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_02_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  background-image: url("../assets/images/code/Group958.png");
  background-size: 100% 100%;
  border: 1px solid #048dfe;
  left: 50px;
}

.dp_02_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_02_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_03_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_03_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_04_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_04_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_05_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_05_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_06_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_06_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color:#A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_07_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_07_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_08_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_08_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_09_3 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #0D1721;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_09_4 {
  width: 240px;
  height: 38px;
  
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #A0ACBB;
  line-height: 19px;
  position: absolute;
  left: 120px;
  text-align: left;
}

.dp_03 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 280px;
  margin-top: -380px;
}

.dp_03_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_03_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  left: 50px;
  top: 0px;
  background-image: url("../assets/images/code/Group959.png");
  /* background-image: url("../assets/images/code/iconbj.svg"); */
  background-size: 100% 100%;
  border: 1px solid #048dfe;
}


.dp_04 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 280px;
  margin-top: -310px;
}

.dp_04_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_04_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  left: 50px;
  top: 0px;
  background-image: url("../assets/images/code/Group960.png");
  background-size: 100% 100%;
  border: 1px solid #048dfe;
}

.dp_05 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 280px;
  margin-top: -240px;
}

.dp_05_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_05_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  left: 50px;
  top: 0px;
  background-image: url("../assets/images/code/Group961.png");
  background-size: 100% 100%;
  border: 1px solid #048dfe;
}

.dp_06 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 280px;
  margin-top: -170px;
}

.dp_06_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_06_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  left: 50px;
  top: 0px;
  background-image: url("../assets/images/code/Group962.png");
  background-size: 100% 100%;
  border: 1px solid #048dfe;
}

.dp_07 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 280px;
  margin-top: -100px;
}

.dp_07_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_07_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  left: 50px;
  top: 0px;
  background-image: url("../assets/images/code/Group963.png");
  background-size: 100% 100%;
  border: 1px solid #048dfe;
}

.dp_08 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 280px;
  margin-top: -30px;
}

.dp_09 {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 280px;
  margin-top: 40px;
}

.dp_08_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_08_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  left: 50px;
  top: 0px;
  background-image: url("../assets/images/code/Group964.png");
  background-size: 100% 100%;
  border: 1px solid #048dfe;
}

.dp_09_1 {
  width: 0px;
  height: 48px;
  opacity: 1;
  position: absolute;
}

.dp_09_2 {
  width: 48px;
  height: 48px;
  border-radius: 0px 0px 0px 0px;
  position: absolute;
  left: 50px;
  top: 0px;
  background-image: url("../assets/images/code/Group965.png");
  background-size: 100% 100%;
  border: 1px solid #048dfe;
}


.dp_back_01 {
  width: 984px;
  height: 594px;
  position: absolute;
  top: 650px;
  left: 610px;
  white-space: pre-wrap;
}

.dp_back_01_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Rectangle566.png");
  background-size: 100% 100%;
}

.dp_back_01_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_01_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}


.dp_back_02_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Frame1007.png");
  background-size: 100% 100%;
}

.dp_back_02_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_02_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}

.dp_back_03_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Rectangle568.png");
  background-size: 100% 100%;
}

.dp_back_03_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_03_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}

.dp_back_04_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Rectangle567.png");
  background-size: 100% 100%;
}

.dp_back_04_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_04_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}


.dp_back_05_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Rectangle564.png");
  background-size: 100% 100%;
}

.dp_back_05_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_05_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}

.dp_back_06_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Rectangle565.png");
  background-size: 100% 100%;
}

.dp_back_06_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_06_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}

.dp_back_07_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Rectangle877.png");
  background-size: 100% 100%;
}

.dp_back_07_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_07_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}

.dp_back_08_img {
  width: 984px;
  height: 432px;
  background: url("../assets/images/code/Rectangle878.png");
  background-size: 100% 100%;
}

.dp_back_08_font {
  width: 984px;
  height: 162px;
  background: linear-gradient(135deg, #0e1926 0%, rgba(14, 25, 38, 0.7) 100%);
  opacity: 1;
}

.dp_back_08_zi {
  width: 936px;
  height: 80px;
  font-size: 14px;
  font-family: HarmonyOS Sans SC-Regular, HarmonyOS Sans SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: left;
  position: relative;
  top: 20px;
  left: 20px;
}
</style>
