import {
	createApp
} from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import './assets/style.css';
import 'element-plus/theme-chalk/index.css'
import animate from "animate.css"
import router from "./router" // index.js 是默认访问
import "swiper/css/swiper.css";

import { monitorZoom } from "@/js/monitorZoom.js";
const m = monitorZoom();
if (window.screen.width * window.devicePixelRatio >= 3840) {
  document.body.style.zoom = 100 / (Number(m) / 2); // 屏幕为 4k 时
} else {
  document.body.style.zoom = 100 / Number(m);
}



createApp(App).use(router).use(ElementPlus).use(animate).mount('#app')